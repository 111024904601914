import { Injectable } from '@angular/core';
import { Settings as Settings } from '@app/shared/models/settings/settings.model';
import { StorageService } from './storage.service';

@Injectable()
export class SettingsService {

    constructor() { }

    get(): Settings {
        const storageString = StorageService.getSettings();
        const settingsObject = JSON.parse(storageString);
        return new Settings(settingsObject);
    }

    save(settings: Settings) {
        const data = JSON.stringify(settings);
        StorageService.saveSettings(data);
    }

    clear() {
        StorageService.clear();
    }

}
