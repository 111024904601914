// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultLanguage: 'en',
  language: 'en',
  storageSecret: 'n33dT0CH4nG3',

  backendConfig: {
    loglevel: 'DEBUG',
    backendUri: 'https://api.dev.nominator.synapps.hu',
    cryptoSalt: 'Y12dWc2dasaJHOUpibTV2UW05dWRYTk9iMjFwYm1GMGIzSXhNak0wTlE9Padsa3'
  },

  firebaseConfig: {
    apiKey: 'AIzaSyCrU8o2UKIXMfW0a3jAvvSYrWyfrHU8MJs',
    authDomain: 'innobonusnominator.firebaseapp.com',
    databaseURL: 'https://innobonusnominator.firebaseio.com',
    projectId: 'innobonusnominator',
    storageBucket: 'innobonusnominator.appspot.com',
    messagingSenderId: '168438291116',
    appId: '1:168438291116:web:b758e05a89086678'
  },

  externalLinkConfig: {
    dashboard: 'https://datastudio.google.com/s/vRWJBBy14a0',
    wall: 'https://wall.dev.nominator.synapps.hu'
  },
  
  chatModuleConfig:{
    enabled:false,
    url:'http://51.116.104.3:4310',
    chatPath:'/comments/',
    chatMessageNumberPath:'/comments/count/',
    tokenLoginPath:'/tokenLogin/',
    chatModuleSiteExternalId:'Innobonus'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
