import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Purchase } from '@app/shared/models/purchases/purchase.model';

@Injectable({
    providedIn: 'root'
})
export class PurchaseService {

    private readonly ENDPOINT = '/purchases';

    constructor(private firebaseAuth: AngularFireAuth, private httpClient: HttpClient) {
    }

    public async list(offset: number, limit: number, query: string = '', sortBy: string = 'createdAt.desc'): Promise<any> {
        const idToken = await (await (this.firebaseAuth.currentUser)).getIdToken();
        const params = new HttpParams({
            fromObject: {
                limit: limit.toString(),
                offset: offset.toString(),
                query,
                sortBy
            }
        });
        const httpOptions = {
            headers: { Authorization: `Bearer ${idToken}`, 'Content-Type': 'application/json' },
            params
        };
        return this.httpClient.get(this.ENDPOINT, httpOptions).toPromise();
    }

    public async create(purchase: Purchase): Promise<any> {
        const idToken = await (await this.firebaseAuth.currentUser).getIdToken();
        const httpOptions = {
            headers: { Authorization: `Bearer ${idToken}`, 'Content-Type': 'application/json' }
        };
        return this.httpClient.post(this.ENDPOINT, purchase, httpOptions).toPromise();
    }

    public async delete(purchase: Purchase): Promise<any> {
        const idToken = await (await this.firebaseAuth.currentUser).getIdToken();
        const httpOptions = {
            headers: { Authorization: `Bearer ${idToken}`, 'Content-Type': 'application/json' }
        };
        return this.httpClient.delete(`${this.ENDPOINT}/${purchase.$id.toString()}`, httpOptions).toPromise();
    }

}
