<ng-template #navbarTemplate>
    <h1 class="breadcrumb" translate>users.title.userList</h1>
</ng-template>

<div class="pt-3 pb-1 px-3 nomination-list-searchbox container-fluid reset-top">
    <div class="row no-gutters justify-content-between">
        <div class="col-12 col-md-6 col-lg-4 col-xl-3">
            <mat-form-field appearance="outline" class="w-100 bonus-design">
                <mat-label translate>filters.search</mat-label>
                <input matInput [(ngModel)]="query" (keyup)="updateFilter($event)">
                <button type="button" matSuffix mat-icon-button (click)="clearFilter()">
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="col-auto align-self-center">
            <button type="button" mat-raised-button color="primary" (click)="createUserClicked()">
                <span translate>users.button.add</span>
            </button>
        </div>
    </div>
</div>

<div class="mx-3 mb-3">
    <table mat-table matSort (matSortChange)="sortData($event)" [matSortActive]="sortEvent.active"
        [matSortDirection]="sortEvent.direction" matSortDisableClear [dataSource]="dataSource"
        class="bonus-design fixed-header">

        <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="name">
                <span translate>users.tableColumns.user</span>
            </th>
            <td mat-cell *matCellDef="let model">
                <img class="avatar" [src]="model.picture" (error)="fallbackToDefault(model)">
                {{model.name}}
            </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="email">
                <span translate>users.tableColumns.email</span>
            </th>
            <td mat-cell *matCellDef="let model" translate>
                {{model.email}}
            </td>
        </ng-container>

        <ng-container matColumnDef="points">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="score">
                <span translate>users.tableColumns.points</span>
            </th>
            <td mat-cell *matCellDef="let model">
                {{model.score}}
            </td>
        </ng-container>

        <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>
                <span translate>users.tableColumns.role</span>
            </th>
            <td mat-cell *matCellDef="let model">
                {{model.role}}
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>
                <span translate>users.tableColumns.status</span>
            </th>
            <td mat-cell *matCellDef="let model">
                <mat-icon *ngIf="model.active" class="icon nomination-plus">
                    brightness_1
                </mat-icon>
                <mat-icon *ngIf="!model.active" class="icon nomination-minus">
                    brightness_1
                </mat-icon>
                <span *ngIf="model.active" translate>users.status.active</span>
                <span *ngIf="!model.active" translate>users.status.inactive</span>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="editUserClicked(row)"></tr>
    </table>

    <mat-paginator [length]="length" [pageSize]="pageEvent.pageSize" [pageIndex]="pageEvent.pageIndex"
        [pageSizeOptions]="pageSizeOptions" (page)="pageData($event)" class="bonus-design">
    </mat-paginator>
</div>