import { Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Purchase } from '@app/shared/models/purchases/purchase.model';
import { Roles } from '@app/shared/models/users/roles.enum';
import { NotificationService } from '@app/shared/services';
import { User } from '@app/shared/models/users/user.model';
import { UserService } from '@app/user-management/services/user.service';
import { ConfirmPurchaseDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { CONFIGURATION } from 'configuration/configuration';
import { ConfirmValidParentMatcher } from '@app/shared/helpers/date-validation-matcher';

@Component({
    selector: 'app-purchase',
    templateUrl: './purchase.component.html',
    styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent {
    readonly roles: string[];
    readonly defaultIssueDate: string;
    users = new Array<User>();
    form: FormGroup;
    existingPurchase: Purchase;
    confirmValidParentMatcher = new ConfirmValidParentMatcher();
    errorMessages: any;

    constructor(
        private readonly dialog: MatDialog,
        private readonly dialogRef: MatDialogRef<PurchaseComponent>,
        private readonly formBuilder: FormBuilder,
        private readonly notification: NotificationService,
        private userService: UserService,
        @Inject(MAT_DIALOG_DATA) private readonly data: Purchase) {
        this.roles = Object.keys(Roles);
        this.defaultIssueDate = new Date().toISOString().split('T')[0];
        this.existingPurchase = data;
        this.createForm();
        this.fetchUserData();
        this.setUpErrorMessages();
    }

    private async fetchUserData() {
        const result: Response = await this.userService.getAllUsers(undefined, undefined, undefined, 'name');
        this.users.length = 0;
        result.data.map(userData => this.users.push(new User(userData)));
        if (this.existingPurchase) {
            this.userIdControl.setValue(this.existingPurchase.$user.$id);
            this.setUserAvaliableScoreFields();
        }
    }

    private createForm() {
        this.form = this.formBuilder.group({
            userId: [{
                value: this.existingPurchase ? this.existingPurchase.$userId : null,
                disabled: this.existingPurchase
            }, Validators.compose([
                Validators.required
            ])],
            description: [{
                value: this.existingPurchase ? this.existingPurchase.$description : '',
                disabled: this.existingPurchase
            }, Validators.compose([
                Validators.required
            ])],
            link: [{
                value: this.existingPurchase ? this.existingPurchase.$link : '',
                disabled: this.existingPurchase
            }, Validators.compose([
                Validators.required
            ])],
            issueDate: [{
                value: this.existingPurchase ? this.existingPurchase.$issueDate : this.defaultIssueDate,
                disabled: this.existingPurchase
            }, Validators.compose([
                Validators.required
            ])],
            score: [{
                value: this.existingPurchase ? this.existingPurchase.$score : 0,
                disabled: this.existingPurchase
            }, Validators.compose([
                Validators.required,
                Validators.min(1)
            ])],
            scoreValue: [{
                value: this.existingPurchase ? this.existingPurchase.$scoreValue : 0,
                disabled: this.existingPurchase
            }, Validators.compose([
                Validators.required
            ])],
            availableScore: [{
                value: null,
                disabled: true
            }],
            availableScoreValue: [{
                value: null,
                disabled: true
            }],
        });

        this.form.controls.score.valueChanges.subscribe(value =>{
            value = Number(value);
            if(value) {
                this.scoreValueControl.setValue(value * CONFIGURATION.purchase.pointValue);
            } 
            
        });
    }

    saveClicked() {
        this.form.markAllAsTouched();

        if (!this.form.valid) {
            this.notification.error('users.notifications.checkFormContent',
                { titleKey: 'users.notifications.correctionRequired' });
            return;
        }
        this.dialogRef.close(this.generateNewPurchaseData());
    }

    deleteClicked() {
        const deleteDialogRef = this.dialog.open(ConfirmPurchaseDeleteComponent);
        deleteDialogRef.afterClosed().subscribe(async (result: any) => {
            if (result) {
                this.dialogRef.close(this.existingPurchase);
            }
        });
    }

    private generateNewPurchaseData(): Purchase {
        return new Purchase({
            userId: this.userId,
            description: this.description,
            link: this.link,
            issueDate: this.getDateString(this.issueDate),
            score: this.score,
            scoreValue: this.scoreValue
        });
    }

    private getDateString(dateObj: any): string {
        let date = new Date(dateObj);
        let dd = date.getDate().toString().padStart(2,'0');
        let mm = (date.getMonth() + 1).toString().padStart(2,'0');
        let yyyy = date.getFullYear();
        return `${yyyy}-${mm}-${dd}`;
    }

    selectedUserChanged(event) {
        this.setUserAvaliableScoreFields();
    }

    private setUserAvaliableScoreFields() {
        let userId = Number(this.userId);
        if (userId) {
            let selectedUser = this.users.filter(user => user.$id == userId)[0];
            this.availableScoreControl.setValue(selectedUser.$score);
            this.availableScoreValueControl.setValue(selectedUser.$score * CONFIGURATION.purchase.pointValue);
        }
        else {
            this.availableScoreControl.setValue(null);
            this.availableScoreValueControl.setValue(null);
        }
    }

    private setUpErrorMessages() {
        this.errorMessages = {
            issueDate: 'Date format is invalid'
        };
    }

    get userIdControl(): AbstractControl {
        return this.form.get('userId');
    }

    get userId(): string {
        return this.userIdControl.value;
    }

    get descriptionControl(): AbstractControl {
        return this.form.get('description');
    }

    get description(): string {
        return this.descriptionControl.value;
    }

    get linkControl(): AbstractControl {
        return this.form.get('link');
    }

    get link(): string {
        return this.linkControl.value;
    }

    get scoreControl(): AbstractControl {
        return this.form.get('score');
    }

    get score(): string {
        return this.scoreControl.value;
    }

    get scoreValueControl(): AbstractControl {
        return this.form.get('scoreValue');
    }

    get scoreValue(): string {
        return this.scoreValueControl.value;
    }

    get availableScoreControl(): AbstractControl {
        return this.form.get('availableScore');
    }

    get availableScoreValueControl(): AbstractControl {
        return this.form.get('availableScoreValue');
    }

    get issueDateControl(): AbstractControl {
        return this.form.get('issueDate');
    }

    get issueDate(): string {
        return this.issueDateControl.value;
    }

    hasError(field: string, validator: string): boolean {
        return this.form.get(field).hasError(validator);
    }

}

interface Response {
    data: Array<any>;
    totalCount: number;
}