import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NominationService } from '@app/nomination-management/services/nomination.service';
import { paths } from '@app/routing-paths';
import { NavbarService } from '@app/user-dashboard/components/navbar/navbar.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-nomination-list-wrapper',
    templateUrl: './nomination-wrapper.component.html',
    styleUrls: ['./nomination-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NominationListWrapperComponent implements OnInit, OnDestroy {

    readonly paths = paths;
    openCount: number;
    closedCount: number;
    @ViewChild('navbarTemplate', { static: true }) navbarTemplate: TemplateRef<any>;
    private openCountChange: Subscription;
    private closedCountChange: Subscription;

    constructor(private readonly nominationService: NominationService,
                private readonly cdr: ChangeDetectorRef,
                private readonly navbarService: NavbarService) {
        this.openCount = 0;
        this.closedCount = 0;
        this.watchCountChanges();
        this.countOpenNominations();
        this.countClosedNominations();
    }

    ngOnInit() {
        this.navbarService.updateTemplate(this.navbarTemplate);
    }

    private watchCountChanges() {
        this.openCountChange = this.nominationService.openCountChange.subscribe((openCount: number) => {
            this.openCount = openCount;
            this.cdr.markForCheck();
        });

        this.closedCountChange = this.nominationService.closedCountChange.subscribe((closedCount: number) => {
            this.closedCount = closedCount;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy() {
        this.openCountChange.unsubscribe();
        this.closedCountChange.unsubscribe();
    }

    private countClosedNominations() {
        this.nominationService.getClosedCount();
    }

    private countOpenNominations() {
        this.nominationService.getOpenCount();
    }
}
