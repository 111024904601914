import { User } from '../users/user.model';

export class Purchase {

    private id: number;
    private userId: number;
    private description: string;
    private link: string;
    private issueDate: any;
    private score: number;
    private scoreValue: number;
    private createdAt: any;
    private createdBy: User;
    private user: User;

    constructor(data?: any) {
        if (data) {
            this.id = data.id || null;
            this.userId = data.userId || null;
            this.description = data.description || '';
            this.link = data.link || '';
            this.score = data.score || 0;
            this.scoreValue = data.scoreValue || 0;
            this.issueDate = data.issueDate || null;
            this.createdAt = data.createdAt || null;
            this.createdBy = new User(data.createdBy) || null;
            this.user = new User(data.user) || null;
        }
    }

    get $id(): number {
        return this.id;
    }

    set $id(value: number) {
        this.id = value;
    }

    get $userId(): number {
        return this.userId;
    }

    set $userId(value: number) {
        this.userId = value;
    }

    get $description(): string {
        return this.description;
    }

    set $description(value: string) {
        this.description = value;
    }

    get $link(): string {
        return this.link;
    }

    set $link(value: string) {
        this.link = value;
    }

    get $issueDate(): any {
        return this.issueDate;
    }

    set $issueDate(value: any) {
        this.issueDate = value;
    }

    get $score(): number {
        return this.score;
    }

    set $score(value: number) {
        this.score = value;
    }

    get $scoreValue(): number {
        return this.scoreValue;
    }

    set $scoreValue(value: number) {
        this.scoreValue = value;
    }

    get $createdAt(): any {
        return this.createdAt;
    }

    set $createdAt(value: any) {
        this.createdAt = value;
    }

    get $createdBy(): User {
        return this.createdBy;
    }

    set $createdBy(value: User) {
        this.createdBy = value;
    }

    get $user(): User {
        return this.user;
    }

    set $user(value: User) {
        this.user = value;
    }

}
