import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class IbnHttpService {

    constructor(private _auth: AngularFireAuth, private http: HttpClient,) {
    }

    Request(requestActionUrl: string, method: HttpMethod = HttpMethod.GET, data: any = null): Promise<any> {

        return this._auth.currentUser.then(currentUser => currentUser.getIdToken().then(idToken => {
            let observable: Observable<any>;
            switch (method) {
                case HttpMethod.GET: {
                    observable = this.http.get(requestActionUrl, {
                        headers: { 'Authorization': `Bearer ${idToken}`, 'Content-Type': 'application/json' },
                        params: data
                    });
                    break;
                }
                case HttpMethod.POST: {
                    observable = this.http.post(requestActionUrl, data, {
                        headers: { 'Authorization': `Bearer ${idToken}`, 'Content-Type': 'application/json', 'Accept': 'application/json' }
                    });
                    break;
                }
                case HttpMethod.PUT: {
                    observable = this.http.put(requestActionUrl, data, {
                        headers: { 'Authorization': `Bearer ${idToken}`, 'Content-Type': 'application/json', 'Accept': 'application/json' }
                    });
                    break;
                }
                default: {
                    throw new Error('Not implemented http method type handling');
                }
            }

            observable = observable.pipe(
                map((response: any) => {
                    return response.data;
                })
            );

            return observable.toPromise();

        })
        );
    }
}

export enum HttpMethod {
    GET,
    POST,
    PUT
}
