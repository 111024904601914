import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { paths } from '@app/routing-paths';
import { SettingsService } from '@app/shared/services';
import { environment } from '@env/environment';
import { DEFAULT_AVATAR } from '../../../user-management/consts/default-avatar.const';
import { UserService } from '../../../user-management/services/user.service';
import { SidenavService } from './sidenav.service';
import { getUserImage } from '@app/shared/utils/image.utils';

@Component({
    selector: 'app-sidenav',
    templateUrl: 'sidenav.component.view.html',
    styleUrls: ['sidenav.component.view.scss']
})
export class SidenavComponent {
    readonly OPEN_NOMINATIONS = `/${paths.NOMINATIONS}/${paths.P_OPENED}`;
    readonly CLOSED_NOMINATIONS = `/${paths.NOMINATIONS}/${paths.P_CLOSED}`;
    readonly DASHBOARD = environment.externalLinkConfig.dashboard;
    readonly WALL = environment.externalLinkConfig.wall;
    readonly USERLIST = paths.USERLIST;
    readonly PURCHASES = paths.PURCHASES;
    userPicture = DEFAULT_AVATAR;
    DEFAULT_AVATAR = DEFAULT_AVATAR;

    constructor(private router: Router,
        private userService: UserService,
        private settingsService: SettingsService,
        private sidenavService: SidenavService) {
        this.loadAvatar();
    }

    private async loadAvatar() {
        const user = await this.userService.getMe();
        this.userPicture = getUserImage(user.email);
    }

    get currentUsersEmail(): string {
        const settings = this.settingsService.get();
        return settings.$email
            ? settings.$email
            : '';
    }

    logout(): void {
        this.settingsService.clear();
        this.router.navigate(['/', paths.LOGOUT]);
    }

    toggleSidenav(): void {
        this.sidenavService.toggle();
    }
}
