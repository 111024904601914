<h4 class="mt-2 mb-2" style="padding-top: 10px">Chat modul</h4>
<mat-card>
    <mat-card-content>
        <iframe
            [src]="chatModulUrl"
            *ngIf="chatModuleEnabled"
            #iframe
            frameBorder="0"
        ></iframe>
    </mat-card-content>
</mat-card>