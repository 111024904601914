<ng-template #navbarTemplate>
    <h1 class="breadcrumb" translate>nominations.title</h1>
</ng-template>

<div class="px-3 pt-3 nomination-list-tab-bar">
    <nav mat-tab-nav-bar disableRipple>
        <a mat-tab-link [routerLink]="['/', paths.NOMINATIONS, paths.P_OPENED]" routerLinkActive
            #rla1="routerLinkActive" [active]="rla1.isActive">
            <span translate>nominations.countOpened</span>
            <span class="pl-2">({{ openCount }})</span>
        </a>
        <a mat-tab-link [routerLink]="['/', paths.NOMINATIONS, paths.P_CLOSED]" routerLinkActive
            #rla2="routerLinkActive" [active]="rla2.isActive">
            <span translate>nominations.countClosed</span>
            <span class="pl-2">({{ closedCount }})</span>
        </a>
    </nav>
</div>

<div class="nomination-list-wrapper">
    <router-outlet></router-outlet>
</div>