import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';

import { Vote } from '@app/shared/models/votes/vote';

@Injectable({
    providedIn: 'root'
})
export class VoteService {

    public constructor(
        private _auth: AngularFireAuth,
        private http: HttpClient) {
    }

    public async getAllByNomination(nominationId: number): Promise<any> {
        return (await this._auth.currentUser).getIdToken().then(idToken => {
            const params = new HttpParams();
            const httpOptions = {
                headers: { 'Authorization': `Bearer ${idToken}`, 'Content-Type': 'application/json' },
                params
            };
            return this.http.get(`/nominations/${nominationId}/votes`, httpOptions).toPromise();
        });
    }

    public async createByNomination(nominationId: number, vote: Vote): Promise<any> {
        return (await this._auth.currentUser).getIdToken().then(idToken => {
            const params = new HttpParams();
            const httpOptions = {
                headers: { 'Authorization': `Bearer ${idToken}`, 'Content-Type': 'application/json' },
                params
            };
            return this.http.post(`/nominations/${nominationId}/votes`, vote, httpOptions).toPromise();
        });
    }
}
