import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';

export interface OptionalParams {
    titleKey?: string;
    titleVariable?: string | number;
    messageVariable?: string | number;
}

enum Severity {
    Success = 'toast-success',
    Info = 'toast-info',
    Warning = 'toast-warning',
    Error = 'toast-error'
}

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private readonly translate: TranslateService, private readonly toastr: ToastrService) {
    }

    success(messageKey: string, params?: OptionalParams): ActiveToast<any> {
        return this.showToast(Severity.Success, messageKey, params);
    }

    info(messageKey: string, params?: OptionalParams): ActiveToast<any> {
        return this.showToast(Severity.Info, messageKey, params);
    }

    warning(messageKey: string, params?: OptionalParams): ActiveToast<any> {
        return this.showToast(Severity.Warning, messageKey, params);
    }

    error(messageKey: string, params?: OptionalParams): ActiveToast<any> {
        return this.showToast(Severity.Error, messageKey, params);
    }

    private showToast(severity: Severity, messageKey: string, params?: OptionalParams): ActiveToast<any> {
        const titleVariable: string = params && params.titleVariable ? params.titleVariable.toString() : '';
        const messageVariable: string = params && params.messageVariable ? params.messageVariable.toString() : '';
        let title = '';
        try {
            title = params && params.titleKey ? this.translate.instant(params.titleKey, { variable: titleVariable }) : '';
        } catch {
            title = params && params.titleKey ? params.titleKey : '';
        }
        let message = '';
        try {
            message = this.translate.instant(messageKey, { variable: messageVariable });
        } catch {
            message = messageKey ? messageKey : '';
        }
        return this.toastr.show(message, title, undefined, severity);
    }

}
