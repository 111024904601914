<div mat-dialog-title translate>
    nominations.labels.requestNominationModify
</div>
<mat-form-field class="w-100" appearance="outline">
    <mat-label translate>nominations.labels.requestNominationModifyPlaceholder</mat-label>
    <textarea matInput [(ngModel)]="moderatorComment" rows="10"></textarea>
</mat-form-field>
<div mat-dialog-actions align="end">
    <button mat-button color="secondary" [mat-dialog-close]="false">
        <span translate>nominations.actions.cancelRequest</span>
    </button>
    <button mat-raised-button color="primary" (click)="sendRequestModification()">
        <span translate>nominations.actions.sendRequest</span>
    </button>
</div>