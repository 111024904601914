import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { SortSource } from './sort-source.enum';

export class Settings {
    private email: string;
    private sortings: SortPageSetting[];

    constructor(data?: any) {
        this.sortings = new Array<SortPageSetting>();
        if (data) {
            this.email = data.email;

            if (data.sortings) {
                this.sortings = data.sortings;
            }
        } else {
            this.email = '';
        }
    }

    get $email(): string {
        return this.email;
    }

    set $email(value: string) {
        this.email = value;
    }

    addSorting(newSetting: SortPageSetting) {
        const fitleredSettings = this.sortings.filter(setting => setting.source !== newSetting.source)
        this.sortings = fitleredSettings;
        this.sortings.push(newSetting);
    }

    getSortingBy(sourtSource: SortSource): SortPageSetting | undefined {
        const existingSetting = this.sortings.filter(setting => setting.source === sourtSource);
        return existingSetting[0];
    }
}

export interface SortPageSetting {
    source: SortSource;
    sort: Sort;
    pageEvent: PageEvent;
}
