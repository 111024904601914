<mat-list>
    <mat-list-item>
        <img matListAvatar [src]="userPicture" (error)="userPicture = DEFAULT_AVATAR">
        <h3 class="text-white font-weight-bold pr-3" matLine>Current user</h3>
        <span matLine class="text-white pr-3">{{ currentUsersEmail }}</span>
    </mat-list-item>
</mat-list>

<mat-nav-list>
    <mat-divider></mat-divider>
    <h3 mat-subheader class="text-white" translate>sidebar.headers.administration</h3>

    <a mat-list-item [routerLink]="OPEN_NOMINATIONS" (click)="toggleSidenav()" class="text-white">
        <i class="material-icons" matListIcon>emoji_people</i>
        <span class="pl-3" translate>sidebar.menuitems.openNominations</span>
    </a>

    <a mat-list-item [routerLink]="CLOSED_NOMINATIONS" (click)="toggleSidenav()" class="text-white">
        <i class="material-icons" matListIcon>done_all</i>
        <span class="pl-3" translate>sidebar.menuitems.closedNominations</span>
    </a>

    <mat-divider></mat-divider>
    <h3 mat-subheader class="text-white" translate>sidebar.headers.status</h3>

    <a mat-list-item [href]="DASHBOARD" (click)="toggleSidenav()" class="text-white" target="_blank">
        <i class="material-icons" matListIcon>view_quilt</i>
        <span class="pl-3" translate>sidebar.menuitems.dashboard</span>
    </a>

    <a mat-list-item [href]="WALL" (click)="toggleSidenav()" class="text-white" target="_blank">
        <i class="material-icons" matListIcon>web</i>
        <span class="pl-3" translate>sidebar.menuitems.wall</span>
    </a>

    <mat-divider></mat-divider>
    <h3 mat-subheader class="text-white" translate>sidebar.headers.management</h3>

    <a mat-list-item [routerLink]="['/', USERLIST]" (click)="toggleSidenav()" class="text-white">
        <i class="material-icons" matListIcon>person</i>
        <span class="pl-3" translate>sidebar.menuitems.userlist</span>
    </a>

    <a mat-list-item [routerLink]="['/', PURCHASES]" (click)="toggleSidenav()" class="text-white">
        <i class="material-icons" matListIcon>attach_money</i>
        <span class="pl-3" translate>sidebar.menuitems.purchases</span>
    </a>

    <mat-divider></mat-divider>

    <a mat-list-item (click)="logout()" class="text-white">
        <i class="material-icons" matListIcon>exit_to_app</i>
        <span class="pl-3" translate>buttons.logout</span>
    </a>
</mat-nav-list>