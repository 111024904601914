import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { StorageService } from '@app/shared/services';
import { environment } from '@env/environment';
import { ChatService } from '../service/chat.service';

@Component({
  selector: 'app-token-login',
  templateUrl: './token-login.component.html',
  styleUrls: ['./token-login.component.scss']
})
export class TokenLoginComponent implements OnInit {

  @Input() customToken = '';
  @ViewChild('iframe') iframe: ElementRef;

  tokenLoginUrl : SafeResourceUrl;
  chatModuleEnabled : boolean=environment.chatModuleConfig.enabled;
  tokenLoginOnload:boolean;

  constructor(private sanitizer:DomSanitizer, private chatService:ChatService, private storageService:StorageService) { }

  ngOnInit() {
    this.tokenLoginOnload=true;
    const url=environment.chatModuleConfig.url+environment.chatModuleConfig.tokenLoginPath+this.customToken+"\\"+environment.chatModuleConfig.chatModuleSiteExternalId;
    this.tokenLoginUrl=this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.chatService.get(url)
      .subscribe(blob =>{
        this.iframe.nativeElement.src = blob;
      });
      
    this.tokenLoginOnload=false;
  }

}
