import { ESCAPE } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NominationService } from '@app/nomination-management/services/nomination.service';
import { paths } from '@app/routing-paths';
import { SortPageSetting } from '@app/shared/models/settings/settings.model';
import { SortSource } from '@app/shared/models/settings/sort-source.enum';
import { SettingsService } from '@app/shared/services';
import { NavbarService } from '@app/user-dashboard/components/navbar/navbar.service';
import { CONFIGURATION } from 'configuration/configuration';
import { UserService } from '../../../user-management/services/user.service';
import { fallbackToDefault, getUserImage } from '@app/shared/utils/image.utils';

@Component({
    selector: 'app-nomination-list-wrapper',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NominationComponent implements OnInit {

    public readonly EMPTYSTATE_IMAGES_NUMBER = 16;
    public readonly URL_NOMINATIONS = paths.NOMINATIONS;
    public readonly DEFAULT_CHAR_INTERVAL = 500;
    public readonly pageSizeOptions: number[];
    public voted: boolean;
    public query: string;
    public length: number;
    public dataSource = new MatTableDataSource<any>();
    public pageEvent: PageEvent;
    public sortEvent: Sort;
    @Input() relatedNomination: any;
    private models = new Array<any>();
    private timer: any;
    protected dataFetchFunction: () => Promise<void>;
    @ViewChild('navbarTemplate', { static: true }) navbarTemplate: TemplateRef<any>;
    fallbackToDefault = fallbackToDefault;

    get currentUsersEmail(): string {
        const settings = this.settingsService.get();
        return settings.$email
            ? settings.$email
            : '';
    }


    constructor(protected nominationService: NominationService,
        protected userService: UserService,
        protected cdr: ChangeDetectorRef,
        protected navbarService: NavbarService,
        protected settingsService: SettingsService,
    ) {
        this.voted = false;
        this.query = '';
        this.length = 0;
        this.pageSizeOptions = CONFIGURATION.paging.pageSizes;
        this.pageEvent = {
            pageIndex: 0,
            pageSize: CONFIGURATION.paging.defaultPageSize,
            length: 0
        };
        this.sortEvent = {
            active: 'createdAt',
            direction: 'desc'
        };
    }

    ngOnInit() {
        this.navbarService.updateTemplate(this.navbarTemplate);
    }

    protected async loadOpenNominations() {
        if (this.relatedNomination) {
            this.query = this.relatedNomination.nominatedUser.name;
            this.pageEvent.pageSize = 9999; //Hardcoded value, until no endpoint on backend for filter by nominated user id
        }
        const offset = this.pageEvent.pageIndex * this.pageEvent.pageSize;
        const sortBy = this.sortEvent.active + '.' + this.sortEvent.direction;
        const data = await this.nominationService.getAllOpened(offset, this.pageEvent.pageSize, this.query, sortBy, this.voted, this.currentUsersEmail);
        this.refreshNominationList(data);
    }

    protected async loadClosedNominations() {
        if (this.relatedNomination) {
            this.query = this.relatedNomination.nominatedUser.name;
            this.pageEvent.pageSize = 9999; //Hardcoded value, until no endpoint on backend for filter by nominated user id
        }
        const offset = this.pageEvent.pageIndex * this.pageEvent.pageSize;
        const sortBy = this.sortEvent.active + '.' + this.sortEvent.direction;
        const data = await this.nominationService.getAllClosed(offset, this.pageEvent.pageSize, this.query, sortBy);
        this.refreshNominationList(data);
    }

    private refreshNominationList(data: any) {
        if (!this.relatedNomination) {
            this.models = data.data;
            this.dataSource.data = this.models;
            this.dataSource.data.forEach(data => {
                data.nominatedUser.picture = getUserImage(data.nominatedUser.email);
            });
            this.length = data.totalCount;
        }
        else {
            this.models = data.data.filter(nomination =>
                nomination.nominatedUser.id == this.relatedNomination.nominatedUser.id &&
                nomination.id != this.relatedNomination.id
            );
            this._checkMissingDescriptions().then(() => {
                this.dataSource.data = this.models;
                this.length = this.models.length;
            });
        }

    }

    private _checkMissingDescriptions(): Promise<any> {
        let promises: Promise<any>[] = [];
        this.models.forEach(model => {
            if (!model.description) {
                promises.push(
                    this.nominationService.getById(model.id).then(nomination => {
                        model.description = nomination.description;
                    })
                );
            }
        });
        return Promise.all(promises);
    }

    requiredAmountOfVoteGiven(model: any): boolean {
        return model.voteStatistics.actVoteCount >= model.voteStatistics.requiredVoteCunt;
    }

    updateFilter(keyboardEvent: any) {
        // tslint:disable-next-line: deprecation
        keyboardEvent.keyCode === ESCAPE
            ? this.clearFilter()
            : this.applyFilter();
    }

    clearFilter() {
        this.query = '';
        this.loadOpenNominations();
    }

    clickRow(row) {
        window.open(`/${paths.NOMINATIONS}/${row.id}`, this.relatedNomination ? '_blank' : '');
    }

    private applyFilter() {
        this.query = this.query.trim();
        this.delayFunction(this.dataFetchFunction.bind(this));
    }

    protected loadSortPageSettings(sortSource: SortSource) {
        const settings = this.settingsService.get();
        const savedSortPaging = settings.getSortingBy(sortSource);
        if (savedSortPaging && savedSortPaging.sort) {
            this.sortEvent = savedSortPaging.sort;
        }
        if (savedSortPaging && savedSortPaging.pageEvent) {
            this.pageEvent = savedSortPaging.pageEvent;
        }
    }

    protected executeSort(sortEvent: Sort, sourceComponent: SortSource) {
        this.sortEvent = sortEvent;
        const settings = this.settingsService.get();
        const sortPageSetting: SortPageSetting = {
            source: sourceComponent,
            sort: this.sortEvent,
            pageEvent: this.pageEvent
        };
        settings.addSorting(sortPageSetting);
        this.settingsService.save(settings);
        this.dataFetchFunction();
    }

    protected executePage(pageEvent: PageEvent, sourceComponent: SortSource) {
        this.pageEvent = pageEvent;
        const settings = this.settingsService.get();
        const sortPageSetting: SortPageSetting = {
            source: sourceComponent,
            sort: this.sortEvent,
            pageEvent: this.pageEvent
        };
        settings.addSorting(sortPageSetting);
        this.settingsService.save(settings);
        this.dataFetchFunction();
    }

    delayFunction(functionToCall: () => Promise<void>, timeoutInMs?: number) {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            clearTimeout(this.timer);
            functionToCall();
        }, timeoutInMs || this.DEFAULT_CHAR_INTERVAL);
    }
}
