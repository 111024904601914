import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appMaxLengthNotifier]'
})
export class MaxInputLengthNotifierDirective implements OnInit {

    // tslint:disable-next-line: no-input-rename
    @Input('maxlength') maxLength: number;
    @Input() ngModel: string;

    private readonly PERCENTAGE_NOTIFICATION_TRESHOLD: number = 80;
    private readonly PERCENTAGE_MAX: number = 100;

    private inputValueLength: number;
    private badge: HTMLSpanElement;
    private parentNode: HTMLElement;
    private inputElement: HTMLInputElement | HTMLTextAreaElement;
    private badgeText: string;

    constructor(private readonly elementRef: ElementRef, private readonly renderer: Renderer2) {
    }

    ngOnInit() {
        this.inputElement = this.elementRef.nativeElement;
        this.parentNode = this.elementRef.nativeElement.parentNode;
        this.inputValueLength = this.ngModel ? this.ngModel.length : 0;
        this.updateBadgeState();
    }

    @HostListener('input', ['$event']) inputChange(event) {
        this.inputValueLength = event.target.value.length;
        this.updateBadgeState();
    }

    private updateBadgeState() {
        if (!this.badgeShouldBeVisible()) {
            return this.removeBadge();
        }

        this.calculateBadgeText();

        if (!this.badgeAlreadyAdded) {
            this.addBadge();
        }
        this.updateBadge();
    }

    private badgeShouldBeVisible(): boolean {
        return this.percentage >= this.PERCENTAGE_NOTIFICATION_TRESHOLD;
    }

    private calculateBadgeText() {
        this.badgeText = this.inputValueLength + ' / ' + this.maxLength;
    }

    private addBadge() {
        this.badge = this.renderer.createElement('span');
        this.renderer.setAttribute(this.badge, 'class', 'position-absolute badge badge-danger');
        this.renderer.setStyle(this.badge, 'top', '-0.2rem');
        this.renderer.setStyle(this.badge, 'right', '-0.4rem');
        this.renderer.insertBefore(this.parentNode, this.badge, this.inputElement);
    }

    private updateBadge() {
        this.badge.innerHTML = this.badgeText;
    }

    private removeBadge() {
        if (this.badgeAlreadyAdded) {
            this.renderer.removeChild(this.parentNode, this.badge);
            this.badge = null;
        }
    }

    get percentage(): number {
        return this.inputValueLength * this.PERCENTAGE_MAX / this.maxLength;
    }

    get badgeAlreadyAdded(): boolean {
        return this.badge != null;
    }
}
