import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NominationClosedListComponent } from './nomination-management/components/list/closed/nomination-closed-list.component';
import { NominationOpenedListComponent } from './nomination-management/components/list/opened/nomination-opened-list.component';
import { NominationListWrapperComponent } from './nomination-management/components/list/wrapper/nomination-wrapper.component';
import { NominationVoteComponent } from './nomination-management/components/vote/nomination-vote.component';
import { PurchaseListComponent } from './purchase-management/list/purchase-list.component';
import { paths } from './routing-paths';
import { AuthGuard } from './shared/guards/auth.guard';
import { LoginComponent } from './user-auth/components/login/login.component';
import { LogoutComponent } from './user-auth/components/logout/logout.component';
import { DashboardLayoutComponent } from './user-dashboard/components/layout/dashboard-layout.component';
import { UserListComponent } from './user-management/user-list/user-list.component';


export const routes: Routes = [
    { path: '', redirectTo: `${paths.NOMINATIONS}/${paths.P_OPENED}`, pathMatch: 'full' },
    // login
    { path: `${paths.LOGIN}`, component: LoginComponent },
    // logout
    { path: `${paths.LOGOUT}`, component: LogoutComponent, canActivate: [AuthGuard] },

    {
        path: '', component: DashboardLayoutComponent, canActivate: [AuthGuard], children: [
            {
                path: `${paths.NOMINATIONS}`, component: NominationListWrapperComponent, canActivate: [AuthGuard], children: [
                    // /nominations/opened
                    { path: `${paths.P_OPENED}`, component: NominationOpenedListComponent },
                    // /nominations/closed
                    { path: `${paths.P_CLOSED}`, component: NominationClosedListComponent },
                ]
            },
            // /nominations/:id
            { path: `${paths.NOMINATIONS}/${paths.P_ID}`, component: NominationVoteComponent },

            { path: paths.USERLIST, component: UserListComponent },

            { path: paths.PURCHASES, component: PurchaseListComponent }
        ],
    },
    { path: '**', redirectTo: `${paths.NOMINATIONS}/${paths.P_OPENED}` }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
