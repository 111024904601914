import { Injectable } from '@angular/core';
import { IbnHttpService, HttpMethod } from '@app/shared/services';

@Injectable({
    providedIn: 'root'
})
export class ScoresService {
    private readonly MyScoreEndpoint = '/scores/users/{me}   ';

    constructor(
        private httpService: IbnHttpService) {
    }

    getUserScore(userId: number): Promise<any> {
        return this.httpService.Request(this.MyScoreEndpoint.replace('{me}', userId.toString()), HttpMethod.GET);
    }
}