import { Component, OnDestroy, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { SidenavService } from '../sidenav/sidenav.service';
import { NavbarService } from './navbar.service';

@Component({
    selector: 'app-navbar',
    templateUrl: 'navbar.component.view.html',
    styleUrls: ['navbar.component.view.scss']
})
export class NavbarComponent implements OnDestroy {

    navbarTemplate: TemplateRef<any>;
    private templateChange: Subscription;

    public constructor(private sidenavService: SidenavService,
                       private navbarService: NavbarService) {
        this.templateChange = this.navbarService.templateChange.subscribe((newTemplate: TemplateRef<any>) => {
            this.navbarTemplate = newTemplate;
        });
    }

    toggleSidenav() {
        this.sidenavService.toggle();
    }

    ngOnDestroy() {
        this.templateChange.unsubscribe();
    }

}
