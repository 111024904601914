<mat-sidenav-container class="d-flex">
    <mat-sidenav #sideNav fixedInViewport>
        <app-sidenav></app-sidenav>
    </mat-sidenav>

    <mat-sidenav-content class="w-100">
        <app-navbar></app-navbar>

        <div class="w-100 main-router-outlet-wrapper">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>