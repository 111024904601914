import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '@env/environment';

@Injectable()
export class StorageService {
    static readonly USER_SETTINGS_DATA_KEY = 'ibn-settings-data';

    constructor() { }

    static clear() {
        localStorage.clear();
    }

    private static getItem(id: string): string {
        return localStorage.getItem(id);
    }

    private static saveItem(id: string, data: string) {
        localStorage.setItem(id, data);
    }

    private static encryptItem(item: any): any {
        try {
            const secret = environment.storageSecret;
            const value = CryptoJS.AES.encrypt(item, secret);
            return value;
        } catch (e) {
            console.error(e);
        }
        return null;
    }

    private static decryptItem(item: any): any {
        try {
            const secret = environment.storageSecret;
            const encoding = CryptoJS.enc.Utf8;
            const value = CryptoJS.AES.decrypt(item, secret).toString(encoding);
            return value;
        } catch (e) {
            console.error(e);
        }
        return null;
    }

    static getSettings(): string {
        const storageData = this.getItem(StorageService.USER_SETTINGS_DATA_KEY);
        return storageData
            ? this.decryptItem(storageData)
            : null;
    }

    static saveSettings(settings: string) {
        const encryted = this.encryptItem(settings);
        if (encryted) {
            this.saveItem(StorageService.USER_SETTINGS_DATA_KEY, encryted);
        }
    }
}

