import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { fadeInOutVerticalAnimation } from '@app/shared/effects/animations';
import { Settings } from '@app/shared/models/settings/settings.model';
import { IbnHttpService, NotificationService, SettingsService } from '@app/shared/services';
import { Roles } from '@app/shared/models/users/roles.enum';
import { environment } from '@env/environment';
import { version, buildNumber, commitId }  from '../../../../../package.json';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: [fadeInOutVerticalAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {
    public userGroup: FormGroup;
    public loginInProgress: boolean;
    version = version;
    buildNumber = buildNumber;
    commitId = commitId;

    constructor(
        private router: Router,
        private auth: AngularFireAuth,
        private notification: NotificationService,
        private formBuilder: FormBuilder,
        private settingsService: SettingsService,
        private cdr: ChangeDetectorRef,
        private http: IbnHttpService ) {
        this.loginInProgress = false;
    }

    ngOnInit(): void {
        this.userGroup = this.formBuilder.group({
            email: new FormControl(null, [Validators.required, Validators.email]),
            password: new FormControl(null, [Validators.required])
        });
    }

    async login() {
        this.userGroup.markAllAsTouched();

        if (!this.userGroup.valid) {
            return this.notification.info('loginPage.message.invalidField');
        }

        this.loginInProgress = true;

        try {
            const credentials = await this.auth.signInWithEmailAndPassword(this.email.value, this.password.value);
            const idTokenResult = await credentials.user.getIdTokenResult();
            await this.getCustomToken();
            this.checkAdminRights(idTokenResult);
        } catch (error) {
            if (error.code === 'auth/invalid-email') {
                this.notification.error('loginPage.message.badEmail',
                    { titleKey: 'loginPage.message.loginFailed' });
            } else {
                this.notification.error('loginPage.message.invalidEmailPassword',
                    { titleKey: 'loginPage.message.loginFailed' });
            }
        }

        this.loginInProgress = false;
        this.cdr.markForCheck();
    }

    private checkAdminRights(idTokenResult: any) {
        if (idTokenResult.claims.ROLE === Roles.MODERATOR) {
            const settings = new Settings({ email: this.email.value });
            this.settingsService.save(settings);
            this.notification.success('auth.message.loggedSuccessfully',
                { titleKey: 'auth.message.loginSuccess' });
            // admin check success, renavigate to default page
            this.router.navigate(['/']);
        } else {
            // admin permission denied
            this.notification.error('auth.message.permissionMissing',
                { titleKey: 'loginPage.message.loginFailed' });
        }
    }

    get email(): FormControl {
        return this.userGroup.get('email') as FormControl;
    }

    get password(): FormControl {
        return this.userGroup.get('password') as FormControl;
    }

    hasError(controlName: string): boolean {
        return this.userGroup.get(controlName).invalid && this.userGroup.get(controlName).touched;
    }

    resetPassword() {
        if (!this.email.value) {
            this.notification.info('loginPage.message.emailMissing');
            return;
        }

        this.auth.sendPasswordResetEmail(
            this.email.value
        ).then(() => {
            this.notification.success('loginPage.message.passwordResetSuccess');
        }).catch(() => {
            this.notification.error('loginPage.message.passwordResetRejected');
        });
    }

    async getCustomToken(){
        const data = await this.http.Request('/users/customController');
        var handle = window.open(environment.chatModuleConfig.url + 
            environment.chatModuleConfig.tokenLoginPath + 
            data + "\\" + 
            environment.chatModuleConfig.chatModuleSiteExternalId, "_blank");
        handle.blur();
        window.focus();
    }
}
