import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { NominationService } from '@app/nomination-management/services/nomination.service';
import { SortSource } from '@app/shared/models/settings/sort-source.enum';
import { SettingsService } from '@app/shared/services';
import { NavbarService } from '@app/user-dashboard/components/navbar/navbar.service';
import { UserService } from '../../../../user-management/services/user.service';
import { NominationComponent } from '../nomination.component';

@Component({
    selector: 'app-nomination-opened-list',
    templateUrl: 'nomination-opened-list.component.view.html',
    styleUrls: ['./nomination-opened-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NominationOpenedListComponent extends NominationComponent implements OnInit {

    public readonly EMPTYSTATE_IMAGES_NUMBER = 16;
    public displayedColumns;
    public defaultDisplayedColumns = ['nominated', 'type', 'category', 'created', 'creator', 'status', 'votes'];
    public relatedDisplayedColumns = ['id', 'created', 'creator', 'status', 'votes', 'description'];
    public goodJobStickerUrl: String;

    constructor(nominationService: NominationService,
        userService: UserService,
        cdr: ChangeDetectorRef,
        navbarService: NavbarService,
        settingsService: SettingsService) {
        super(nominationService, userService, cdr, navbarService, settingsService);
        this.dataFetchFunction = this.loadOpenNominations.bind(this);
        this.loadSortPageSettings(SortSource.OPEN_NOMINATIONS);
        this.voted = false; //default status
    }

    ngOnInit() {
        this.displayedColumns = this.relatedNomination ? this.relatedDisplayedColumns : this.defaultDisplayedColumns;
        this.dataFetchFunction();
        this.goodJobStickerUrl = '../../../../assets/images/gj-stickers/' +
            (Math.floor(Math.random() * this.EMPTYSTATE_IMAGES_NUMBER) + 1) +
            '.png';
    }

    sortData(sortEvent: Sort) {
        this.executeSort(sortEvent, SortSource.OPEN_NOMINATIONS);
    }

    pageData(pageEvent: PageEvent) {
        this.executePage(pageEvent, SortSource.OPEN_NOMINATIONS);
    }
}
