import { ESCAPE } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Purchase } from '@app/shared/models/purchases/purchase.model';
import { NotificationService } from '@app/shared/services';
import { NavbarService } from '@app/user-dashboard/components/navbar/navbar.service';
import { CONFIGURATION } from 'configuration/configuration';
import { PurchaseComponent } from '../add/purchase.component';
import { PurchaseService } from '../purchase.service';

@Component({
    selector: 'app-purchase-list',
    templateUrl: './purchase-list.component.html',
    styleUrls: ['./purchase-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PurchaseListComponent implements OnInit {

    @ViewChild('navbarTemplate', { static: true }) navbarTemplate: TemplateRef<any>;
    public readonly displayedColumns = ['id', 'user', 'description', 'score', 'issueDate', 'createdAt', 'createdBy', 'link'];
    public dataSource = new MatTableDataSource<any>();
    public pageEvent: PageEvent;
    public query: string;
    public length: number;
    public sortEvent: Sort;
    public readonly pageSizeOptions: number[];
    private models = new Array<Purchase>();
    private timer: any;
    public readonly DEFAULT_CHAR_INTERVAL = 500;

    constructor(private readonly navbarService: NavbarService,
        private readonly dialog: MatDialog,
        private readonly cdr: ChangeDetectorRef,
        private readonly notification: NotificationService,
        private readonly purchaseService: PurchaseService) {
        this.pageSizeOptions = CONFIGURATION.paging.pageSizes;
        this.pageEvent = {
            pageIndex: 0,
            pageSize: CONFIGURATION.paging.defaultPageSize,
            length: 0
        };
        this.query = '';
        this.sortEvent = {
            active: 'createdAt',
            direction: 'desc'
        };
        this.fetchPurchaseData();
    }

    ngOnInit() {
        this.navbarService.updateTemplate(this.navbarTemplate);
    }

    private async fetchPurchaseData() {
        const offset = this.pageEvent.pageIndex * this.pageEvent.pageSize;
        const sortBy = this.sortEvent.active + '.' + this.sortEvent.direction;
        const result: any = await this.purchaseService.list(offset, this.pageEvent.pageSize, this.query, sortBy);
        this.models = [];
        result.data.map((purchaseData: any) => this.models.push(new Purchase(purchaseData)));
        this.dataSource.data = this.models;
        this.length = result.totalCount;
        this.cdr.markForCheck();
    }

    pageData(pageEvent: PageEvent) {
        this.pageEvent = pageEvent;
        this.fetchPurchaseData();
    }

    sortData(sortEvent: Sort) {
        this.sortEvent = sortEvent;
        this.fetchPurchaseData();
    }

    updateFilter(keyboardEvent: KeyboardEvent) {
        // tslint:disable-next-line: deprecation
        keyboardEvent.keyCode === ESCAPE
            ? this.clearFilter()
            : this.applyFilter();
    }

    clearFilter() {
        this.query = '';
        this.fetchPurchaseData();
    }

    private applyFilter() {
        this.query = this.query.trim();
        this.pageEvent.pageIndex = 0;
        this.delayFunction(this.fetchPurchaseData.bind(this));
    }

    delayFunction(functionToCall: () => Promise<void>, timeoutInMs?: number) {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            clearTimeout(this.timer);
            functionToCall();
        }, timeoutInMs || this.DEFAULT_CHAR_INTERVAL);
    }

    async createPurchaseClicked() {
        const dialogRef = this.dialog.open(PurchaseComponent);
        dialogRef.afterClosed().subscribe(async (purchase: Purchase) => {
            if (purchase) {
                this.purchase(purchase).then(response => {
                    if (response.data) {
                        this.notification.success('purchases.notifications.purchaseCreated',
                            { titleKey: 'purchases.notifications.purchaseCreated' });
                        this.fetchPurchaseData();
                    }
                });
            }
        });
    }

    editPurchaseClicked(purchase) {
        const dialogRef = this.dialog.open(PurchaseComponent, {
            data: purchase
        });
        dialogRef.afterClosed().toPromise().then((result: any) => {
            if (result) {
                this.deletePurchase(purchase).then(response => {
                    if (response.data) {
                        this.notification.success('purchases.notifications.purchaseDeleted',
                            { titleKey: 'purchases.notifications.purchaseDeleted' });
                    }
                }).catch(err => {
                    this.notification.error('purchases.notifications.purchaseNotDeleted',
                        { titleKey: 'purchases.notifications.purchaseNotDeleted' });
                }).finally(()=> this.fetchPurchaseData());
            }
        });
    }

    private purchase(purchase: Purchase): Promise<any> {
        return this.purchaseService.create(purchase);
    }

    private deletePurchase(purchase:Purchase) : Promise<any> {
        return this.purchaseService.delete(purchase);
    }

    //private editUser(userData: User): Promise<any> {
    //    return this.userService.editUser(userData);
    //}

    onLinkClicked($event, link) {
        window.open(link, '_blank');
        $event.cancelBubble = true;
    }

}

interface Response {
    data: Array<any>;
    totalCount: number;
}
