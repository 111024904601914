import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Purchase } from '@app/shared/models/purchases/purchase.model';
import { NotificationService } from '@app/shared/services';
import { UserService } from '@app/user-management/services/user.service';

@Component({
    selector: 'app-purchase-delete-confirm',
    templateUrl: './confirm-delete.component.html',
    styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmPurchaseDeleteComponent {

    constructor(private readonly dialogRef: MatDialogRef<ConfirmPurchaseDeleteComponent>,
        private readonly formBuilder: FormBuilder,
        private readonly notification: NotificationService,
        private userService: UserService,
        @Inject(MAT_DIALOG_DATA) private readonly data: Purchase) {
        
    }

    confirmClicked() {
        this.dialogRef.close(true);
    }

}