import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { User, UserView } from '@app/shared/models/users/user.model';
import { HttpMethod, IbnHttpService } from '@app/shared/services';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private readonly USER_ADMINISTRATION_ENDPOINT = '/admin/users';

    public constructor(
        private firebaseAuth: AngularFireAuth,
        private httpClient: HttpClient,
        private http: IbnHttpService
    ) {
    }

    public list(): Promise<any> {
        return this.http.Request('/users');
    }

    public async getAllUsers(offset: number, limit: number, query: string = '', sortBy: string = 'createdAt.desc'): Promise<any> {
        const idToken = await this.currenFirebaseUserIdToken();

        const fromObject: any = {
            query,
            sortBy
        }
        if (limit) fromObject.limit = limit.toString();
        if (offset) fromObject.offset = offset.toString();

        const params = new HttpParams({
            fromObject: fromObject
        });
        const httpOptions = {
            headers: { Authorization: `Bearer ${idToken}`, 'Content-Type': 'application/json' },
            params
        };
        return this.httpClient.get(this.USER_ADMINISTRATION_ENDPOINT, httpOptions).toPromise();
    }

    public async createUser(user: User): Promise<any> {
        return this.http.Request(this.USER_ADMINISTRATION_ENDPOINT, HttpMethod.POST, user);
    }

    public async editUser(user: User): Promise<any> {
        return this.http.Request(`${this.USER_ADMINISTRATION_ENDPOINT}/${user.$id}`, HttpMethod.PUT, user);
    }

    public async getMe(): Promise<UserView> {
        return this.http.Request('/users/me');
    }

    public async currenFirebaseUserIdToken(): Promise<string> {
        return (await this.firebaseAuth.currentUser).getIdToken();
    }
}
