import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { StorageService } from '@app/shared/services';
import { environment } from '@env/environment';
import { ChatService } from '../service/chat.service';

@Component({
  selector: 'app-message-number',
  templateUrl: './message-number.component.html',
  styleUrls: ['./message-number.component.scss']
})
export class MessageNumberComponent implements OnInit {

  @Input() item = '';
  @ViewChild('iframe') iframe: ElementRef;
  
  chatMessageNumberUrl : SafeResourceUrl;
  chatModuleEnabled : boolean=environment.chatModuleConfig.enabled;
  
  constructor(private sanitizer:DomSanitizer, private chatService:ChatService, private storageService:StorageService) { }


  ngOnInit() {
    const url=environment.chatModuleConfig.url + environment.chatModuleConfig.chatMessageNumberPath + this.item

    this.chatMessageNumberUrl=this.sanitizer.bypassSecurityTrustResourceUrl(url);
    /*
    this.chatService.get(url)
      .subscribe(blob =>{
        this.iframe.nativeElement.src = blob;
      });*/
  }

}
