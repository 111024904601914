import { Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Roles } from '@app/shared/models/users/roles.enum';
import { User, UserForCreate } from '@app/shared/models/users/user.model';
import { NotificationService } from '@app/shared/services';
import { getUserImage } from '../../shared/utils/image.utils';
import { DEFAULT_AVATAR } from '../consts/default-avatar.const';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserCreateComponent {
    PASSWORD_MIN_LENGTH = 6;
    readonly roles: string[];
    readonly user: User;
    readonly editMode: boolean;
    userForm: FormGroup;
    DEFAULT_AVATAR = DEFAULT_AVATAR;

    constructor(
        private readonly dialogRef: MatDialogRef<UserCreateComponent>,
        private readonly formBuilder: FormBuilder,
        private readonly notification: NotificationService,
        @Inject(MAT_DIALOG_DATA) private readonly data: User) {
        this.user = this.data;
        this.editMode = this.user ? true : false;
        this.roles = Object.keys(Roles);
        this.createForm();
    }

    private createForm() {
        this.userForm = this.formBuilder.group({
            name: [{
                value: this.editMode ? this.user.$name : '',
                disabled: this.editMode
            }, Validators.compose([
                Validators.required
            ])],
            email: [{
                value: this.editMode ? this.user.$email : '',
                disabled: this.editMode
            }, Validators.compose([
                Validators.required,
                Validators.email
            ])],
            role: [
                this.editMode ? this.user.$role : Roles.EMPLOYEE
                , Validators.compose([
                    Validators.required
                ])],
            active: [{
                value: this.editMode ? this.user.$active : true,
                disabled: !this.editMode
            }, Validators.compose([
                Validators.required
            ])],
            picture: [this.editMode ? getUserImage(this.user.$email): DEFAULT_AVATAR],
        });
        if (!this.editMode) {
            this.userForm.addControl('password', new FormControl('',
                [Validators.required, Validators.minLength(6), Validators.maxLength(50)])
            );
            this.userForm.addControl('passwordConfirm', new FormControl('',
                [Validators.required, Validators.minLength(6), Validators.maxLength(50)])
            );
        }
    }

    async saveClicked() {
        this.userForm.markAllAsTouched();

        if (!this.userForm.valid) {
            this.notification.error('users.notifications.checkFormContent',
                { titleKey: 'users.notifications.correctionRequired' });
            return;
        }

        if (!this.editMode && this.userForm.controls.password.value !== this.userForm.controls.passwordConfirm.value) {
          this.notification.error('users.notifications.passwordsDoNotMatch');
          return;
        }

        const data = await this.generateUserData();
        this.dialogRef.close(data);
    }

    roleChange(event: MatSelectChange) {
        this.roleControl.setValue(event.value);
    }

    private async generateUserData(): Promise<UserForCreate> {
      let user = new UserForCreate({
        id: this.id,
        name: this.name,
        email: this.email,
        role: this.role,
        active: this.active,
        password: this.userForm.get('password') ? this.userForm.get('password').value : null,
        passwordConfirm: this.userForm.get('passwordConfirm') ? this.userForm.get('passwordConfirm').value : null,
      });
      
      return user;
    }

    get nameControl(): AbstractControl {
        return this.userForm.get('name');
    }

    get name(): string {
        return this.nameControl.value;
    }

    get emailControl(): AbstractControl {
        return this.userForm.get('email');
    }

    get email(): string {
        return this.emailControl.value;
    }

    get roleControl(): AbstractControl {
        return this.userForm.get('role');
    }

    get role(): string {
        return this.roleControl.value;
    }

    get activeControl(): AbstractControl {
        return this.userForm.get('active');
    }

    get active(): string {
        return this.activeControl.value;
    }

    get activityString(): string {
        return this.active
            ? 'active'
            : 'inactive';
    }

    get id(): number | null {
        return this.editMode
            ? this.user.$id
            : null;
    }

    hasError(field: string, validator: string): boolean {
        return this.userForm.get(field).hasError(validator);
    }
}
