import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

export const fadeInOutVerticalAnimation: AnimationTriggerMetadata = trigger('fadeInOutVerticalAnimation', [
    transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-50%)' }),
        animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ opacity: '*', transform: 'translateY(0)', }))
    ]),
    transition(':leave', [
        style({ opacity: '*', transform: 'translateY(0)' }),
        animate('250ms cubic-bezier(0.8, 0.0, 0.2, 1)', style({ opacity: 0, transform: 'translateY(-50%)', }))
    ])
]);
