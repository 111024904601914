import { Component, Input } from '@angular/core';
import { Status } from '@app/shared/models/nominations/status.enum';

@Component({
    selector: 'app-nomination-status-badge',
    templateUrl: './nomination-status-badge.component.html',
    styleUrls: ['nomination-status-badge.component.scss']
})
export class NominationStatusBadgeComponent {
    @Input() extraClasses: string;
    @Input() nominationStatus: Status;

    constructor() {
        this.extraClasses = '';
    }

    get statusClass(): string {
        if (this.nominationStatus === Status.CREATED) {
            return 'badge-light';
        }

        if (this.nominationStatus === Status.PENDING) {
            return 'badge-warning';
        }

        if (this.nominationStatus === Status.MODIFY) {
            return 'badge-info';
        }

        if (this.nominationStatus === Status.DECLINED) {
            return 'badge-danger';
        }

        if (this.nominationStatus === Status.APPROVED) {
            return 'badge-success';
        }
    }
}
