import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from '@app/shared/services';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private notification: NotificationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError((httpError: HttpErrorResponse) => {
            this.handleError(httpError);

            const error = httpError.error.message || httpError.statusText;
            return throwError(error);
        }));
    }

    private handleError(httpError: HttpErrorResponse): any {

        console.log('handleError', httpError);
        if (httpError.status == 0) {
            this.handleNotification('server_not_available');
        } else if (httpError.error instanceof ErrorEvent) {
            console.error('HttpService Unknown error:', httpError.error.message);
            this.handleNotification('unknown_error');
        } else {
            // The backend returned an unsuccessful response code.
            const errResponse = httpError.error;
            console.error('Backend error:', errResponse.message);
            this.handleNotification(errResponse.message);
        }
        return throwError(httpError); // do not send back error objects if error occured
    }

    private handleNotification(title: string, description: string = '') {
        if (title || description) {
            this.notification.error(description, { titleKey: title });
        }
    }
}
