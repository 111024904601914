import { Injectable } from '@angular/core';
import { IbnHttpService, HttpMethod } from '@app/shared/services';

@Injectable({
    providedIn: 'root'
})
export class CategoriesService {
    private readonly categoriesEndpoint = '/categories';

    constructor(
        private httpService: IbnHttpService) {
    }

    getCategories(): Promise<any[]> {
        return this.httpService.Request(this.categoriesEndpoint, HttpMethod.GET);
    }

}