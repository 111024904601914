export const CONFIGURATION: Configuration = {
    paging: {
        pageSizes: [15, 50, 100, 500],
        defaultPageSize: 100
    },
    nomination: {
        description: {
            length: {
                min: 70,
                max: 384
            },
        },
        moderatorComment: {
            length: {
                min: 0,
                max: 160
            }
        }
    },
    purchase: {
        pointValue: 1500
    }
};

interface PagingConfiguration {
    pageSizes: number[];
    defaultPageSize: number;
}

interface Configuration {
    paging: PagingConfiguration;
    nomination: NominationConfiguration;
    purchase: PurchaseConfiguration;
}

interface NominationConfiguration {
    description: LengthConfiguration;
    moderatorComment: LengthConfiguration;
}

interface PurchaseConfiguration {
    pointValue: number;
}

interface LengthConfiguration {
    length: {
        min: number;
        max: number;
    };
}
