import { Component } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { AppSpinnerComponent } from './app-spinner.component';

@Component({
  selector: 'app-root',
  template:
    `<ng-http-loader
      [backdrop]="true"
      [backgroundColor]="'#000000'"
      [debounceDelay]="100"
      [extraDuration]="300"
      [minDuration]="700"
      [filteredUrlPatterns]="[]"
      [filteredMethods]="['OPTIONS']"
      [entryComponent]="spinnerComponent">
    </ng-http-loader>
    <router-outlet></router-outlet>`
})
export class AppInitializrComponent {
  public spinnerComponent = AppSpinnerComponent;

  public constructor(
    protected translate: TranslateService) {
    this._initTranslation();
  }

  protected _initTranslation() {
    this.translate.setDefaultLang(environment.defaultLanguage);
    // the lang to use, if the lang isn't available,
    // it will use the current loader to get them
    this.translate.use(environment.language);
  }
}
