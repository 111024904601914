import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class ConfirmValidParentMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return !this.isDate(control.value) && control.touched;
    }

    private isDate(date: any): boolean {
        if (date && !isNaN(Date.parse(date))){
            return true;
        }
        return false;
    }
}