<div *ngIf="!relatedNomination" class="pt-4 pb-1 px-3 nomination-list-searchbox container-fluid">
    <div class="row no-gutters">
        <div class="col-12 col-md-6 col-lg-4">
            <div class="d-flex w-100">
                <mat-form-field appearance="outline" class="w-100 bonus-design">
                    <mat-label translate>filters.search</mat-label>
                    <input matInput [(ngModel)]="query" (keyup)="updateFilter($event)">
                    <button type="button" matSuffix mat-icon-button (click)="clearFilter()">
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-form-field>
                <mat-checkbox [(ngModel)]="voted" (change)="updateFilter($event)" class="ml-5" color="primary" matSuffix translate>haven't voted yet</mat-checkbox>
            </div>
        </div>
    </div>
</div>

<div class="mx-3 mb-3" [ngClass]="{'d-none': length == 0}"> 
    <table mat-table matSort (matSortChange)="sortData($event)" [matSortActive]="sortEvent.active"
        [matSortDirection]="sortEvent.direction" matSortDisableClear [dataSource]="dataSource"
        class="bonus-design" [ngClass]="{ 'fixed-header': !relatedNomination }" >

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>
                <span translate>nominations.tableColumns.id</span>
            </th>
            <td mat-cell *matCellDef="let model">
                <span>#{{model.id}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="nominated">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="nominatedUser">
                <span translate>nominations.tableColumns.nominated</span>
            </th>
            <td mat-cell *matCellDef="let model">
                <img class="avatar" [src]="model.nominatedUser.picture" (error)="fallbackToDefault(model.nominatedUser)">
                <span>{{model.nominatedUser.name}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="categoryType">
                <span translate>nominations.tableColumns.type</span>
            </th>
            <td mat-cell *matCellDef="let model" translate>
                nominations.categoryTypes.{{model.category.categoryType}}
            </td>
        </ng-container>

        <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="category">
                <span translate>nominations.tableColumns.category</span>
            </th>
            <td mat-cell *matCellDef="let model">
                <mat-icon *ngIf="model.nominationType === 'PLUS'" class="icon nomination-plus">
                    brightness_1
                </mat-icon>
                <mat-icon *ngIf="model.nominationType === 'MINUS'" class="icon nomination-minus">
                    brightness_1
                </mat-icon>
                {{model.category.name}}
            </td>
        </ng-container>

        <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="createdAt">
                <span translate>nominations.tableColumns.created</span>
            </th>
            <td mat-cell *matCellDef="let model">
                {{model.createdAt | timeago}}
            </td>
        </ng-container>

        <ng-container matColumnDef="creator">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="creatorUser">
                <span translate>nominations.tableColumns.creator</span>
            </th>
            <td mat-cell *matCellDef="let model">
                {{model.creatorUser.name}}
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="status">
                <span translate>nominations.tableColumns.status</span>
            </th>
            <td mat-cell *matCellDef="let model">
                <app-nomination-status-badge [nominationStatus]="model.status"></app-nomination-status-badge>
            </td>
        </ng-container>

        <ng-container matColumnDef="votes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="votes">
                <span translate>nominations.tableColumns.votes</span>
            </th>
            <td mat-cell *matCellDef="let model">
                <span class="badge" [ngClass]="requiredAmountOfVoteGiven(model) ? 'badge-success': 'badge-danger'">
                    {{model.voteStatistics.actVoteCount}}/{{model.voteStatistics.requiredVoteCunt}}
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>
                <span translate>nominations.tableColumns.description</span>
            </th>
            <td mat-cell *matCellDef="let model" class="w-50">
                <span>{{model.description}}</span>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="clickRow(row)"></tr>
    </table>
  
    <mat-paginator *ngIf="!relatedNomination" [length]="length" [pageSize]="pageEvent.pageSize" [pageIndex]="pageEvent.pageIndex"
        [pageSizeOptions]="pageSizeOptions" (page)="pageData($event)" class="bonus-design">
    </mat-paginator>
</div>

<div [ngClass]="{'d-none': length != 0}">
    <img [src] = "goodJobStickerUrl" class="mx-auto emptyState"/>
</div>



