import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NominationService } from '../../../nomination-management/services/nomination.service';

@Component({
    selector: 'app-request-modification-dialog',
    templateUrl: './request-modification-dialog.component.html',
    styleUrls: ['./request-modification-dialog.component.scss']
})
export class RequestModificationDialogComponent {
    public moderatorComment: string;
    private nomination: any;

    public constructor(
        private service: NominationService,
        public dialogRef: MatDialogRef<RequestModificationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.nomination = data;
    }

    public async sendRequestModification() {
        this.nomination.moderatorComment = this.moderatorComment;
        await this.service.requestModification(this.nomination.id, this.nomination);
        this.dialogRef.close(true);
    }
}
