import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from '../sidenav/sidenav.service';

@Component({
    selector: 'app-dashboard-layout',
    templateUrl: 'dashboard-layout.component.view.html',
    styleUrls: ['dashboard-layout.component.view.scss']
})
export class DashboardLayoutComponent implements OnInit {
    @ViewChild('sideNav', { static: true })
    sideNav: MatSidenav;

    constructor(private sidenavService: SidenavService) {
    }

    ngOnInit() {
        this.sidenavService.setSidenav(this.sideNav);
    }
}
