import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { SortSource } from '@app/shared/models/settings/sort-source.enum';
import { SettingsService } from '@app/shared/services';
import { NavbarService } from '@app/user-dashboard/components/navbar/navbar.service';
import { NominationService } from 'app/nomination-management/services/nomination.service';
import { UserService } from '../../../../user-management/services/user.service';
import { NominationComponent } from '../nomination.component';

@Component({
    selector: 'app-nomination-closed-list',
    templateUrl: 'nomination-closed-list.component.view.html',
    styleUrls: ['./nomination-closed-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NominationClosedListComponent extends NominationComponent implements OnInit {

    public displayedColumns;
    public defaultDisplayedColumns = ['nominated', 'type', 'category', 'created', 'creator', 'status', 'closedBy', 'point'];
    public relatedDisplayedColumns = ['id', 'created', 'creator', 'status', 'point', 'description'];
    
    constructor(nominationService: NominationService,
                userService: UserService,
                cdr: ChangeDetectorRef,
                navbarService: NavbarService,
                settingsService: SettingsService) {
        super(nominationService, userService, cdr, navbarService, settingsService);
        this.dataFetchFunction = this.loadClosedNominations.bind(this);
        this.loadSortPageSettings(SortSource.CLOSED_NOMINATIONS);
    }

    ngOnInit() {
        this.displayedColumns = this.relatedNomination ? this.relatedDisplayedColumns : this.defaultDisplayedColumns;
        this.dataFetchFunction();
    }

    sortData(sortEvent: Sort) {
        this.executeSort(sortEvent, SortSource.CLOSED_NOMINATIONS);
    }

    pageData(pageEvent: PageEvent) {
        this.executePage(pageEvent, SortSource.CLOSED_NOMINATIONS);
    }
}
