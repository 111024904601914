import { ESCAPE } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { User } from '@app/shared/models/users/user.model';
import { NotificationService } from '@app/shared/services';
import { NavbarService } from '@app/user-dashboard/components/navbar/navbar.service';
import { CONFIGURATION } from 'configuration/configuration';
import { DEFAULT_AVATAR } from '../consts/default-avatar.const';
import { UserService } from '../services/user.service';
import { UserCreateComponent } from '../user/user.component';
import { fallbackToDefault, getUserImage } from '@app/shared/utils/image.utils';

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserListComponent implements OnInit {

    @ViewChild('navbarTemplate', { static: true }) navbarTemplate: TemplateRef<any>;
    public readonly displayedColumns = ['user', 'email', 'points', 'role', 'status'];
    public dataSource = new MatTableDataSource<any>();
    public pageEvent: PageEvent;
    public query: string;
    public length: number;
    public sortEvent: Sort;
    public readonly pageSizeOptions: number[];
    private models = new Array<User>();
    private timer: any;
    public readonly DEFAULT_CHAR_INTERVAL = 500;
    fallbackToDefault = fallbackToDefault;

    constructor(
        private readonly navbarService: NavbarService,
        private readonly userService: UserService,
        private readonly dialog: MatDialog,
        private readonly cdr: ChangeDetectorRef,
        private readonly notification: NotificationService) {
        this.pageSizeOptions = CONFIGURATION.paging.pageSizes;
        this.pageEvent = {
            pageIndex: 0,
            pageSize: CONFIGURATION.paging.defaultPageSize,
            length: 0
        };
        this.query = '';
        this.sortEvent = {
            active: 'createdAt',
            direction: 'desc'
        };
        this.fetchUserData();
    }

    ngOnInit() {
        this.navbarService.updateTemplate(this.navbarTemplate);
    }

    private async fetchUserData() {
        const offset = this.pageEvent.pageIndex * this.pageEvent.pageSize;
        const sortBy = this.sortEvent.active + '.' + this.sortEvent.direction;
        const result: Response = await this.userService.getAllUsers(offset, this.pageEvent.pageSize, this.query, sortBy);
        this.models.length = 0;
        result.data.map(userData => this.models.push(
            new User({
                ...userData,
                picture: DEFAULT_AVATAR
            }))
        );
        this.models.forEach(user => {
            user.$picture = getUserImage(user.$email);
        });
        this.dataSource.data = this.models;
        this.length = result.totalCount;
        this.cdr.markForCheck();
    }

    pageData(pageEvent: PageEvent) {
        this.pageEvent = pageEvent;
        this.fetchUserData();
    }

    sortData(sortEvent: Sort) {
        this.sortEvent = sortEvent;
        this.fetchUserData();
    }

    updateFilter(keyboardEvent: KeyboardEvent) {
        // tslint:disable-next-line: deprecation
        keyboardEvent.keyCode === ESCAPE
            ? this.clearFilter()
            : this.applyFilter();
    }

    clearFilter() {
        this.query = '';
        this.fetchUserData();
    }

    private applyFilter() {
        this.query = this.query.trim();
        this.pageEvent.pageIndex = 0;
        this.delayFunction(this.fetchUserData.bind(this));
    }

    delayFunction(functionToCall: () => Promise<void>, timeoutInMs?: number) {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            clearTimeout(this.timer);
            functionToCall();
        }, timeoutInMs || this.DEFAULT_CHAR_INTERVAL);
    }

    async createUserClicked() {
        const dialogRef = this.dialog.open(UserCreateComponent, {
            maxHeight: '90vh',
            maxWidth: '80vw'
        });
        dialogRef.afterClosed().subscribe(async (result: any) => {
            if (result) {
                const response = await this.createNewUser(result);

                if (response) {
                    this.notification.success('users.notifications.userCreated',
                        { titleKey: 'users.notifications.userCreated' });
                    this.fetchUserData();
                }
            }
        });
    }

    async editUserClicked(user: User) {
        const dialogRef = this.dialog.open(UserCreateComponent, {
            data: user
        });
        dialogRef.afterClosed().subscribe(async (result: any) => {
            if (result) {
                const response = await this.editUser(result);

                if (response) {
                    this.notification.success('users.notifications.userUpdated',
                        { titleKey: 'users.notifications.userUpdated' });
                    this.fetchUserData();
                }
            }
        });
    }

    private createNewUser(userData: User): Promise<any> {
        return this.userService.createUser(userData);
    }

    private editUser(userData: User): Promise<any> {
        return this.userService.editUser(userData);
    }
}

interface Response {
    data: Array<any>;
    totalCount: number;
}
