<h2 mat-dialog-title>
    <span translate>purchases.title.confirmDelete</span>
</h2>

<mat-dialog-actions align="end">
    <button type="button" mat-button [mat-dialog-close]="null">
        <span translate>purchases.button.cancel</span>
    </button>
    <button type="button" mat-raised-button color="warn" (click)="confirmClicked()">
        <span translate>purchases.button.delete</span>
    </button>
</mat-dialog-actions>