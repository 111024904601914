import { Component, NgZone, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { paths } from '@app/routing-paths';
import { NotificationService } from '@app/shared/services';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private auth: AngularFireAuth,
    private router: Router,
    private ngZone: NgZone,
    private notification: NotificationService) {
  }

  ngOnInit() {
    this.auth.signOut().then(result => {
      this.notification.success('auth.message.loggedOutSuccessfully', { titleKey: 'auth.message.logoutSuccess' });
      this.ngZone.run(() => this.router.navigate([paths.LOGIN])).then();
    }).
      catch(error => {
        this.ngZone.run(() => this.router.navigate([paths.LOGIN])).then();
      });
  }
}
