import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpMethod, IbnHttpService } from '@app/shared/services';

@Injectable({
    providedIn: 'root'
})
export class NominationService {

    @Output() openCountChange = new EventEmitter<number>();
    @Output() closedCountChange = new EventEmitter<number>();

    public constructor(
        private firebaseAuth: AngularFireAuth,
        private httpClient: HttpClient,
        private httpService: IbnHttpService) {
    }

    public async getAllOpened(offset: number, limit: number, query: string = '', sortBy: string = 'createdAt.desc', voted: boolean, userEmail: String): Promise<any> {
        const idToken = await (await this.firebaseAuth.currentUser).getIdToken();
        const params = new HttpParams({
            fromObject: {
                limit: limit.toString(),
                offset: offset.toString(),
                query,
                sortBy,
                voted: voted.toString(),
                userEmail: userEmail.toString()
            }
        });
        const httpOptions = {
            headers: { Authorization: `Bearer ${idToken}`, 'Content-Type': 'application/json' },
            params
        };
        return this.httpClient.get('/nominations/opened', httpOptions).toPromise();
    }

    public async getAllClosed(offset: number, limit: number, query: string = '', sortBy: string = 'createdAt.desc'): Promise<any> {
        const idToken = await (await this.firebaseAuth.currentUser).getIdToken();
        const params = new HttpParams({
            fromObject: {
                limit: limit.toString(),
                offset: offset.toString(),
                query,
                sortBy
            }
        });
        const httpOptions = {
            headers: { Authorization: `Bearer ${idToken}`, 'Content-Type': 'application/json' },
            params
        };
        return this.httpClient.get('/nominations/closed', httpOptions).toPromise();
    }

    public getById(id: number): Promise<any> {
        return this.httpService.Request('/nominations/' + id);
    }

    public save(id: number, data: any): Promise<void> {
        return this.httpService.Request('/nominations/' + id.toString(), HttpMethod.PUT, data);
    }

    public decline(id: number, data: any): Promise<void> {
        return this.httpService.Request(`/nominations/${id.toString()}/decline`, HttpMethod.POST, data);
    }

    public reopen(id: number, data: any): Promise<void> {
        return this.httpService.Request(`/nominations/${id.toString()}/reOpen`, HttpMethod.POST, data);
    }

    public approve(id: number, data: any): Promise<void> {
        return this.httpService.Request(`/nominations/${id.toString()}/approve`, HttpMethod.POST, data);
    }

    public async getOpenCount() {
        const data: any = await this.httpService.Request('/nominations/opened/count');
        this.openCountChange.next(data);
    }

    public async getClosedCount() {
        const data: any = await this.httpService.Request('/nominations/closed/count');
        this.closedCountChange.next(data);
    }

    public async requestModification(id: number, data: any) {
        return this.httpService.Request(`/nominations/${id}/requestModification`, HttpMethod.POST, data);
    }
}
