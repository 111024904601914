import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { User } from '@firebase/auth-types';
import { Observable } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { paths } from '../../routing-paths';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private auth: AngularFireAuth) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.auth.authState.pipe(
            concatMap((user: User) => {
                if (user) {
                    return user.getIdTokenResult()
                }
                else {
                    return Promise.resolve(null)
                }
            }),
            map(idTokenResult => {
                if (idTokenResult) {
                    return true;
                    /* //IF ADMIN PERMISSION NEEDS TO BE CHECKED
                    if (idTokenResult.claims.admin) { //permission check
                        console.log('Logged in');
                        return true;
                    }
                    else {
                        if (!this._isFirstCheck) {
                            console.log('Permission missing');
                        }
                        this.router.navigate([paths.LOGIN]);
                        return false;
                    }*/
                }
                else {
                    //not logged in
                    this.router.navigate([paths.LOGIN]);
                    return false;
                }
            }),
            tap(() => { })
        );
    }
}
