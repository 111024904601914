<h2 mat-dialog-title>
    <ng-container *ngIf="editMode; then editModeTemplate; else createModeTemplate"></ng-container>

    <ng-template #editModeTemplate>
        <span translate>users.title.editUser</span>
    </ng-template>

    <ng-template #createModeTemplate>
        <span translate>users.title.createUser</span>
        <small class="ml-2" translate>users.title.createUserHint</small>
    </ng-template>
</h2>

<form [formGroup]="userForm" novalidate>
    <div class="pic-uploader-container">
        <div class="left">
            <img class="preview" [src]="userForm.get('picture').value" (error)="userForm.get('picture').setValue(DEFAULT_AVATAR)">
        </div>
    </div>

    <mat-form-field class="w-100 reduce-height" appearance="outline">
        <mat-label translate>users.labels.name</mat-label>
        <input formControlName="name" matInput>
        <mat-error *ngIf="hasError('name', 'required')">
            <span translate>
                users.errors.mandatory
            </span>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100 reduce-height" appearance="outline">
        <mat-label translate>users.labels.email</mat-label>
        <input formControlName="email" type="email" matInput>
        <mat-error *ngIf="hasError('email', 'required')">
            <span translate>
                users.errors.mandatory
            </span>
        </mat-error>
        <mat-error *ngIf="hasError('email', 'email')">
            <span translate>
                users.errors.emailFormat
            </span>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100 reduce-height" appearance="outline">
        <mat-label translate>users.labels.role</mat-label>
        <mat-select (selectionChange)="roleChange($event)" formControlName="role">
            <mat-option *ngFor="let role of roles" [value]="role">
                {{role}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('role', 'required')">
            <span translate>
                users.errors.mandatory
            </span>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100 reduce-height" appearance="outline" *ngIf="!editMode">
        <mat-label translate>users.labels.password</mat-label>
        <input formControlName="password" type="password" matInput autocomplete="off" maxlength="50" minlength="6">
        <mat-error *ngIf="hasError('password', 'required')">
            <span translate>
                users.errors.mandatory
            </span>
        </mat-error>
        <mat-error *ngIf="hasError('password', 'minlength')">
            <span translate [translateParams]="{minlength: PASSWORD_MIN_LENGTH}">
                users.errors.allowedMinLength
            </span>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100 reduce-height" appearance="outline" *ngIf="!editMode">
        <mat-label translate>users.labels.passwordConfirm</mat-label>
        <input formControlName="passwordConfirm" type="password" matInput autocomplete="off" maxlength="50"
            [minLength]="PASSWORD_MIN_LENGTH">
        <mat-error *ngIf="hasError('passwordConfirm', 'required')">
            <span translate>
                users.errors.mandatory
            </span>
        </mat-error>
        <mat-error *ngIf="hasError('passwordConfirm', 'minlength')">
            <span translate [translateParams]="{minlength: PASSWORD_MIN_LENGTH}">
                users.errors.allowedMinLength
            </span>
        </mat-error>
    </mat-form-field>

    <mat-slide-toggle color="primary" formControlName="active">
        <span translate>users.labels.status</span>
        <span class="pr-2">:</span>
        <span translate>users.status.{{activityString}}</span>
    </mat-slide-toggle>

    <mat-dialog-actions align="end">
        <button type="button" mat-button [mat-dialog-close]="null">
            <span translate>users.button.cancel</span>
        </button>
        <button type="button" mat-button (click)="saveClicked()">
            <span translate>users.button.save</span>
        </button>
    </mat-dialog-actions>
</form>
