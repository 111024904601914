import { DEFAULT_AVATAR } from "@app/user-management/consts/default-avatar.const";

export function getUserImage(email: string, inBig: boolean = false): string {
    const uname = email.split('@')[0].toLowerCase();
    return `https://static.brighthills.com/images/employees/${uname}@brighthills.com-${inBig ? '800x800' : '150x150'}.png`;
}

// returns default avatar if user has no picture
export function fallbackToDefault(user: any): void {
    user.picture = DEFAULT_AVATAR;
}
