import { Roles } from './roles.enum';

export class User {

    private id: number;
    private name: string;
    private email: string;
    private score: number;
    private role: Roles;
    private active: boolean;
    private picture: string;

    constructor(data?: any) {
        if (data) {
            this.id = data.id || null;
            this.name = data.name || '';
            this.email = data.email || '';
            this.score = data.score || 0;
            this.role = data.role || Roles.EMPLOYEE;
            this.active = data.active || false;
            if(data.picture) {
              this.picture = data.picture || '';
            }
        }
    }

    get $id(): number {
        return this.id;
    }

    set $id(value: number) {
        this.id = value;
    }

    get $name(): string {
        return this.name;
    }

    set $name(value: string) {
        this.name = value;
    }

    get $email(): string {
        return this.email;
    }

    set $email(value: string) {
        this.email = value;
    }

    get $score(): number {
        return this.score;
    }

    set $score(value: number) {
        this.score = value;
    }

    get $role(): Roles {
        return this.role;
    }

    set $role(value: Roles) {
        this.role = value;
    }

    get $active(): boolean {
        return this.active;
    }

    set $active(value: boolean) {
        this.active = value;
    }

    get $picture(): string {  // byte array string
        return this.picture;
    }

    set $picture(value: string) {  // byte array string
        this.picture = value;
    }

}

export class UserForCreate extends User {
    private password: string;
    private passwordConfirm: string;

    constructor(data?: any) {
        super(data);
        if (data) {
            this.password = data.password || null;
            this.passwordConfirm = data.passwordConfirm || null;
        }
    }

    get $password(): string {
        return this.password;
    }
    get $passwordConfirm(): string {
        return this.passwordConfirm;
    }

}

export class UserView {
    id: number;
    name: string;
    email: string;
    picture: string; // base64
}
