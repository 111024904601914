<div class="container-fluid h-100">
    <div class="row h-100">
        <div class="d-none d-md-block col-md-6 col-lg-7 col-xl-8 login-page h-100">
        </div>
        <div class="col-md-6 col-lg-5 col-xl-4 login-form-box overflow-auto h-100 p-3 p-md-5">
            <img src="assets/images/logo.svg">

            <h1 translate>loginPage.texts.title</h1>
            <h2 translate class="py-1 py-md-3">loginPage.texts.subtitle</h2>

            <form [formGroup]="userGroup" (ngSubmit)="login()" class="login-form" novalidate>
                <div class="input-wrapper text-left">
                    <label for="email" translate>loginPage.labels.email</label>
                    <input mat-input id="email" class="w-100" type="text" autocomplete="email"
                        [placeholder]="'loginPage.placeholders.email' | translate" [formControl]="email" required="true"
                        autofocus>
                    <div *ngIf="hasError('email')" [@fadeInOutVerticalAnimation] class="text-danger" translate>
                        errors.required
                    </div>
                </div>

                <div class="input-wrapper text-left">
                    <label for="password" translate>loginPage.labels.password</label>
                    <input id="password" class="w-100" type="password" autocomplete="current-password"
                        [placeholder]="'loginPage.placeholders.password' | translate" [formControl]="password"
                        required="true">
                    <div *ngIf="hasError('password')" [@fadeInOutVerticalAnimation] class="text-danger" translate>
                        errors.required
                    </div>
                </div>

                <div>
                    <a [routerLink]="" (click)="resetPassword()" class="mt-3">
                        <span class="link" translate>loginPage.links.forgotPassword</span>
                    </a>

                    <button mat-raised-button [color]="'primary'" type="submit" [disabled]="loginInProgress"
                        class="mt-3">
                        <span translate>loginPage.button.login</span>
                    </button>
                </div>
            </form>
            <span id="version" [title]="version + ' #' + buildNumber + ' (' + commitId + ')'">{{version}}</span>
        </div>
    </div>
</div>