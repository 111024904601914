import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { ChatService } from '../service/chat.service'
import { StorageService } from '../../shared/services/storage.service'

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  @Input() item = '';
  @ViewChild('iframe') iframe: ElementRef;

  chatModulUrl : SafeResourceUrl;
  chatModuleEnabled : boolean=environment.chatModuleConfig.enabled;

  constructor(private sanitizer:DomSanitizer, private chatService:ChatService, private storageService:StorageService) { }

  ngOnInit() {
    const url=environment.chatModuleConfig.url+environment.chatModuleConfig.chatPath+this.item;
    
    this.chatModulUrl=this.sanitizer.bypassSecurityTrustResourceUrl(url);
    /*this.chatService.get(url)
      .subscribe(blob =>{
        this.iframe.nativeElement.src = blob;
      });*/
  }


}
