<ng-template #navbarTemplate>
    <h1 class="breadcrumb" translate>purchases.title.purchases</h1>
</ng-template>

<div class="pt-3 pb-1 px-3 nomination-list-searchbox container-fluid reset-top">
    <div class="row no-gutters justify-content-between">
        <div class="col-12 col-md-6 col-lg-4 col-xl-3">
            <mat-form-field appearance="outline" class="w-100 bonus-design">
                <mat-label translate>filters.search</mat-label>
                <input matInput [(ngModel)]="query" (keyup)="updateFilter($event)">
                <button type="button" matSuffix mat-icon-button (click)="clearFilter()">
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="col-auto align-self-center">
            <button type="button" mat-raised-button color="primary" (click)="createPurchaseClicked()">
                <span translate>purchases.button.purchase</span>
            </button>
        </div>
    </div>
</div>

<div class="mx-3 mb-3">
    <table mat-table matSort (matSortChange)="sortData($event)" [matSortActive]="sortEvent.active"
        [matSortDirection]="sortEvent.direction" matSortDisableClear [dataSource]="dataSource"
        class="bonus-design fixed-header">

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>
                <span translate>purchases.tableColumns.id</span>
            </th>
            <td mat-cell *matCellDef="let model" translate>
                {{ model.$id }}
            </td>
        </ng-container>

        <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="user">
                <span translate>purchases.tableColumns.purchaser</span>
            </th>
            <td mat-cell *matCellDef="let model">
                {{ model.$user.$name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>
                <span translate>purchases.tableColumns.description</span>
            </th>
            <td mat-cell *matCellDef="let model" translate>
                {{ model.$description }}
            </td>
        </ng-container>

        <ng-container matColumnDef="score">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="score">
                <span translate>purchases.tableColumns.score</span>
            </th>
            <td mat-cell *matCellDef="let model">
                {{ model.$score }}
            </td>
        </ng-container>

        <ng-container matColumnDef="issueDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="issueDate">
                <span translate>purchases.tableColumns.issueDate</span>
            </th>
            <td mat-cell *matCellDef="let model">
                {{ model.$issueDate }}
            </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="createdAt">
                <span translate>purchases.tableColumns.createdAt</span>
            </th>
            <td mat-cell *matCellDef="let model">
                {{ model.$createdAt }}
            </td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="createdBy">
                <span translate>purchases.tableColumns.createdBy</span>
            </th>
            <td mat-cell *matCellDef="let model">
                {{ model.$createdBy.$name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="link">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="link">
                <span translate>purchases.tableColumns.link</span>
            </th>
            <td mat-cell *matCellDef="let model" (click)="onLinkClicked($event, model.$link)">
                <mat-label class="link" translate>purchases.labels.clickHere</mat-label>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="editPurchaseClicked(row)"></tr>
    </table>

    <mat-paginator [length]="length" [pageSize]="pageEvent.pageSize" [pageIndex]="pageEvent.pageIndex"
        [pageSizeOptions]="pageSizeOptions" (page)="pageData($event)" class="bonus-design">
    </mat-paginator>
</div>