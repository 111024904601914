export const paths: any = {
    P_ID: ':id',
    P_OPENED: 'opened',
    P_CLOSED: 'closed',

    LOGIN: 'login',
    LOGOUT: 'logout',

    DASHBOARD: 'dashboard',

    NOMINATIONS: 'nominations',

    USERLIST: 'userlist',

    PURCHASES: 'purchases'
};
