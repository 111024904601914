<h2 mat-dialog-title>
    <span *ngIf="!existingPurchase" translate>purchases.title.create</span>
    <span *ngIf="existingPurchase" translate>purchases.title.delete</span>
</h2>

<form [formGroup]="form" novalidate>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label translate>purchases.labels.userName</mat-label>
        <mat-select formControlName="userId" (selectionChange)="selectedUserChanged($event)">
            <mat-option *ngFor="let user of users" [value]="user.$id">
                {{user.$name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div class="d-flex">
        <mat-form-field class="w-50 reduce-height pr-2" appearance="outline">
            <mat-label translate>purchases.labels.availableScore</mat-label>
            <input formControlName="availableScore" type="number" matInput>
        </mat-form-field>
        <mat-form-field class="w-50 reduce-height pl-2" appearance="outline">
            <mat-label translate>purchases.labels.availableScoreValue</mat-label>
            <input formControlName="availableScoreValue" type="number" matInput>
        </mat-form-field>
    </div>

    <mat-form-field class="w-100 reduce-height" appearance="outline">
        <mat-label translate>purchases.labels.description</mat-label>
        <textarea formControlName="description" matInput></textarea>
        <mat-error *ngIf="hasError('description', 'required')">
            <span translate>
                purchases.errors.required
            </span>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100 reduce-height" appearance="outline">
        <mat-label translate>purchases.labels.link</mat-label>
        <input formControlName="link" matInput>
        <mat-error *ngIf="hasError('link', 'required')">
            <span translate>
                purchases.errors.required
            </span>
        </mat-error>
    </mat-form-field>

    <div class="d-flex">
        <mat-form-field class="w-50 reduce-height pr-2" appearance="outline">
            <mat-label translate>purchases.labels.score</mat-label>
            <input formControlName="score" type="number" matInput>
            <mat-error *ngIf="hasError('score', 'required')">
                <span translate>
                    purchases.errors.required
                </span>
            </mat-error>
            <mat-error *ngIf="hasError('score', 'min')">
                <span translate>
                    purchases.errors.min
                </span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-50 reduce-height pl-2" appearance="outline">
            <mat-label translate>purchases.labels.scoreValue</mat-label>
            <input formControlName="scoreValue" type="number" matInput>
        </mat-form-field>
    </div>

    <mat-form-field class="w-100 reduce-height" appearance="outline">
        <mat-label translate>purchases.labels.issueDate</mat-label>
        <input formControlName="issueDate" [matDatepicker]="picker" [errorStateMatcher]="confirmValidParentMatcher"
            matInput>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error>
            {{errorMessages.issueDate}}
        </mat-error>
    </mat-form-field>

    <mat-dialog-actions align="end">
        <button type="button" mat-button [mat-dialog-close]="null">
            <span translate>purchases.button.cancel</span>
        </button>
        <button *ngIf="!existingPurchase" type="button" mat-raised-button color="primary" (click)="saveClicked()">
            <span translate>purchases.button.save</span>
        </button>
        <!-- <button *ngIf="existingPurchase" type="button" color="warn" mat-raised-button (click)="deleteClicked()">
            <span translate>purchases.button.delete</span>
        </button> -->
    </mat-dialog-actions>
</form>