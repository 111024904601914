import { EventEmitter, Injectable, Output, TemplateRef } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NavbarService {

    @Output() templateChange = new EventEmitter<TemplateRef<any>>();

    private templateRef: TemplateRef<any>;

    updateTemplate(templateRef: TemplateRef<any>) {
        this.templateRef = templateRef;
        this.templateChange.next(this.templateRef);
    }

}
