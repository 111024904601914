<ng-template #navbarTemplate>
    <div *ngIf="nomination" class="container px-0">
        <div class="row">
            <div class="col col-sm-12">
                <h1 class="d-flex align-items-center">
                    <img class="avatar" [src]="userPicture" (error)="userPicture = DEFAULT_AVATAR">
                    <span translate [translateParams]="{username: nomination.nominatedUser.name, id: nomination?.id}">
                        nominations.headings.nominationTitle
                    </span>
                    <small>
                        <app-nomination-status-badge extraClasses="ml-2" [nominationStatus]="nomination.status">
                        </app-nomination-status-badge>
                    </small>
                </h1>
            </div>
        </div>
    </div>
</ng-template>

<ng-container *ngIf="nomination">
    <div class="container py-3">
        <div class="row">
            <div class="col col-sm-12">

                <h4 class="mt-2 mb-2">
                    <span translate [translateParams]="{username: nomination.nominatedUser.name}">
                        nominations.headings.userName
                    </span>
                    <small translate>
                        nominations.headings.statistics
                    </small>
                    <div class="messageNumber" id="messageNumber" *ngIf="chatModuleEnabled">
                        <app-message-number  [item]="nomination.id"></app-message-number>
                        <div class="messageNumberPointer" (click)="jumpToChat()" ></div>
                        <button mat-raised-button class="mat-raised-button mat-button-base mat-primary ng-star-inserted" 
                        (click)="jumpToChat()" style="line-height: 29px;">{{ 'nominations.headings.jumpToChat' |translate }}</button>
                    </div>
                </h4>

                <mat-card>
                    <mat-card-content>
                        <div *ngIf="score" class="container-fluid p-0">
                            <div class="row">
                                <div class="col-12 col-12 col-md-4">
                                    <div class="information-box">
                                        <h6 translate>nominations.headings.pointsSummary</h6>
                                        <h3>{{score.balance | number:'1.0-2'}}</h3>
                                    </div>
                                </div>
                                <div class="col-12 col-12 col-md-4">
                                    <div class="information-box my-3 my-md-0">
                                        <h6 translate>nominations.headings.socialRate</h6>
                                        <h3>
                                            {{score.statistics.socialScorePercentage | number:'1.0-2'}}% -
                                            {{score.statistics.socialScore | number:'1.0-2'}}
                                            <small> points</small>
                                        </h3>
                                    </div>
                                </div>
                                <div class="col-12 col-12 col-md-4">
                                    <div class="information-box">
                                        <h6 translate>nominations.headings.professionalRate</h6>
                                        <h3>
                                            {{score.statistics.professionalScorePercentage | number:'1.0-2'}}% -
                                            {{score.statistics.professionalScore | number:'1.0-2'}}
                                            <small> points</small>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </mat-card-content>
                </mat-card>

                <h4 class="mt-4 mb-2">
                    <span translate>nominations.headings.nominationDetails</span>
                    <small class="pl-2" translate>nominations.headings.publicSection</small>
                </h4>
               
                <mat-card>
                    <mat-card-content>
                        <form [formGroup]="nominationForm" novalidate>
                            <h3 class="mat-card-header-text mat-card-header-text-inner">
                                <mat-icon *ngIf="nomination.nominationType === 'PLUS'"
                                    class="icon nomination-plus mb-1">
                                    brightness_1
                                </mat-icon>
                                <mat-icon *ngIf="nomination.nominationType === 'MINUS'"
                                    class="icon nomination-minus mb-1">
                                    brightness_1
                                </mat-icon>
                                <span
                                    [innerHTML]="'nominations.headings.nominationBy'
                                    | translate:{username: nomination.creatorUser.name, createdAt: nomination.createdAt | timeago}">
                                </span>
                            </h3>

                            <div class="container-fluid p-0">
                                <div class="row">
                                    <div class="col-sm">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label translate>nominations.labels.type</mat-label>
                                            <input formControlName="nominationCategoryType" matInput>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label translate>nominations.labels.category</mat-label>
                                            <mat-select (selectionChange)="nominationCategoryChange($event)"
                                                formControlName="nominationCategoryId">
                                                <mat-optgroup *ngFor="let categoriesType of categoryTypes"
                                                    [label]="categoriesType.name">
                                                    <mat-option *ngFor="let category of categoriesType.categories"
                                                        [value]="category.id">
                                                        {{category.name}}
                                                    </mat-option>
                                                </mat-optgroup>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label translate>nominations.labels.nominationDescription</mat-label>
                                            <textarea formControlName="nominationDescription" matInput rows="6"
                                                appMaxLengthNotifier [maxlength]="DESCRIPTION_MAX_LENGTH"></textarea>
                                            <mat-error *ngIf="hasError('nominationDescription', 'required')">
                                                <span translate>
                                                    nominations.errors.mandatory
                                                </span>
                                            </mat-error>
                                            <mat-error *ngIf="hasError('nominationDescription', 'maxlength')">
                                                <span translate [translateParams]="{maxlength: DESCRIPTION_MAX_LENGTH}">
                                                    nominations.errors.allowedMaxLength
                                                </span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-sm">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label translate>nominations.labels.moderatorComment</mat-label>
                                            <textarea formControlName="moderatorComment" matInput rows="6"
                                                appMaxLengthNotifier
                                                [maxlength]="MODERATOR_COMMENT_MAX_LENGTH"></textarea>
                                            <mat-error *ngIf="hasError('moderatorComment', 'maxlength')">
                                                <span translate
                                                    [translateParams]="{maxlength: MODERATOR_COMMENT_MAX_LENGTH}">
                                                    nominations.errors.allowedMaxLength
                                                </span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="row" *ngIf="nominationGifURL">
                                    <div class="mx-3 mt-2 mb-4 d-flex flex-column align-items-center">
                                        <img class="gif-image" src="{{nominationGifURL}}">
                                        <label *ngIf="isInProgress" class="text-danger cursor-pointer"
                                            (click)="removeGif()" translate>nominations.actions.removeGif</label>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="isInProgress">
                                <mat-chip-list>
                                    <mat-chip *ngFor="let moderatorComment of defaultModeratorComments"
                                        (click)="changeModeratorComment(moderatorComment)">
                                        {{ moderatorComment }}
                                    </mat-chip>
                                </mat-chip-list>
                            </div>
                        </form>
                    </mat-card-content>

                    <mat-divider *ngIf="isInProgress"></mat-divider>

                    <mat-card-actions *ngIf="isInProgress" class="text-right">
                        <button mat-raised-button [color]="'primary'" type="submit" (click)="saveNomination()"
                            [disabled]="!saveAllowedInCurrentStatus">
                            <span translate>nominations.actions.save</span>
                        </button>
                    </mat-card-actions>
                </mat-card>

                <h4 class="mt-4 mb-2">
                    <span translate>nominations.headings.voteAndFinalization</span>
                    <small class="pl-2" translate>nominations.headings.privateSection</small>
                </h4>
                
                <mat-card>
                    <mat-card-content *ngIf="finalizeMode">
                        <h3 class="mat-card-header-text mat-card-header-text-inner">
                            <span translate>
                                nominations.headings.votes
                            </span>
                            <span class="badge" [ngClass]="requiredAmountOfVoteGiven ? 'badge-success': 'badge-danger'">
                                {{ nomination.voteStatistics.actVoteCount}}/{{nomination.voteStatistics.requiredVoteCunt }}
                            </span>
                        </h3>

                        <table mat-table [dataSource]="votes">
                            <ng-container matColumnDef="voter">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span translate>votes.tableColumns.voter</span>
                                </th>
                                <td mat-cell *matCellDef="let model" class="text-nowrap">
                                    {{model.user.name}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="score">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span translate class="px-2">votes.tableColumns.score</span>
                                </th>
                                <td mat-cell *matCellDef="let model" class="px-2">
                                    {{model.score}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="description">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span translate>votes.tableColumns.description</span>
                                </th>
                                <td mat-cell *matCellDef="let model">
                                    {{model.description}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="created">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span translate>votes.tableColumns.created</span>
                                </th>
                                <td mat-cell *matCellDef="let model" class="text-nowrap">
                                    {{model.createdAt | timeago}}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </mat-card-content>

                    <mat-card-content class="mb-0">
                        <h3 class="mat-card-header-text mat-card-header-text-inner">
                            <span *ngIf="voteMode" translate>nominations.headings.addYourVote</span>
                            <span *ngIf="finalizeMode" translate>nominations.headings.finalizeNomination</span>
                            <span *ngIf="finalizedNomination" translate>nominations.headings.finalizedNomination</span>
                        </h3>

                        <form name="voteForm" #f="ngForm">
                            <div class="vote-line">
                                <div class="fibonacci">
                                    <ng-container *ngFor="let score of fibos">
                                        <button mat-raised-button class="fibo-box"
                                            [ngClass]="{'active': score === voteScore}" (click)="setVoteScore(score)"
                                            [disabled]="isClosed">
                                            {{ score }}
                                        </button>
                                    </ng-container>
                                </div>
                                
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label translate>nominations.labels.note</mat-label>
                                    <textarea matInput rows="2" [(ngModel)]="voteDescription" name="voteDescription"
                                        required [disabled]="isClosed"></textarea>
                                </mat-form-field>

                                <div *ngIf="!isClosed" class="pb-3">
                                    <mat-chip-list>
                                        <mat-chip *ngFor="let description of defaultVoteDescriptions"
                                            (click)="changeDescription(description)">
                                            {{ description }}
                                        </mat-chip>
                                    </mat-chip-list>
                                </div>
                               
                                <div *ngIf="voteMode" class="text-right pb-3">
                                    <button class="mr-3" [disabled]="isModificationInProgress" mat-raised-button
                                        [color]="'primary'" (click)="requestNominationToModify()">
                                        <span translate>nominations.actions.requestModification</span>
                                    </button>

                                    <button [disabled]="f.form.invalid || !voteCanBeSent" mat-raised-button
                                        [color]="'primary'" (click)="saveVote()">
                                        <span translate>votes.actions.save</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </mat-card-content>
                  
                    <mat-divider *ngIf="isInProgress || isClosed"></mat-divider>
                    <mat-card-actions class="text-right">
                        <div *ngIf="isInProgress">
                            <span [matTooltipDisabled]="atLeastOneVoteGiven"
                                [matTooltip]="'nominations.tooltips.atLeastOneVoteToDecline' | translate">
                                <button [disabled]="!atLeastOneVoteGiven" mat-raised-button [color]="'warn'"
                                    (click)="declineNomination()">
                                    <span translate>nominations.actions.decline</span>
                                </button>
                            </span>

                            <button *ngIf="finalizeMode" mat-raised-button [color]="'primary'"
                                (click)="approveNomination()">
                                <span translate>nominations.actions.approve</span>
                            </button>
                        </div>
                      
                        <button *ngIf="isClosed" mat-raised-button [color]="'primary'" (click)="reopenNomination()">
                            <span translate>nominations.actions.reopen</span>
                        </button>
                    </mat-card-actions>
                </mat-card>
                <div id="chatModul" *ngIf="chatModuleEnabled">
                    <app-chat [item]="nomination?.id"></app-chat>
                </div>
                <mat-card-content class="mb-0">
                    <h3 class="mat-card-header-text mat-card-header-text-inner mt-4 mb-2">
                        <span translate>nominations.headings.relatedNominations</span>
                    </h3>

                    <div class="px-3 pt-3 nomination-list-tab-bar">
                        <nav mat-tab-nav-bar disableRipple>
                            <a mat-tab-link (click)="changeRelatedSelector(true)" [active]="relatedSelectorOpen">
                                <span translate>nominations.countOpened</span>
                            </a>
                            <a mat-tab-link (click)="changeRelatedSelector(false)" [active]="!relatedSelectorOpen">
                                <span translate>nominations.countClosed</span>
                            </a>
                        </nav>
                    </div>

                    <app-nomination-closed-list *ngIf="!relatedSelectorOpen" [relatedNomination]="nomination">
                    </app-nomination-closed-list>

                    <app-nomination-opened-list *ngIf="relatedSelectorOpen" [relatedNomination]="nomination">
                    </app-nomination-opened-list>

                </mat-card-content>
            </div>
        </div>
    </div>
</ng-container>