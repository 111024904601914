import { Component } from '@angular/core';

@Component({
    selector: 'ldu-spinner',
    template:
        `<mat-progress-spinner 
            [color]="'primary'"
            [mode]="'indeterminate'">
        </mat-progress-spinner>`
})
export class AppSpinnerComponent {
}
