import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders} from '@angular/common/http';
//import { ResponseContentType, Http, RequestOptions, Headers } from '@angular/http';
import { Subscriber,Observable } from 'rxjs';

@Injectable()
export class ChatService{
    constructor(private httpClient:HttpClient){}

    get(url:string): Observable<any>{

        return new Observable((observer: Subscriber<any>) => {
            let objectUrl: string = null;
            console.log(url);
            this.httpClient
                .get(url)
                .subscribe((m:any) => {
                    console.log(m);
                    objectUrl = URL.createObjectURL(m.blob());
                    observer.next(objectUrl);
                });

            return () => {
                console.log(objectUrl);
                if (objectUrl) {
                    URL.revokeObjectURL(objectUrl);
                    objectUrl = null;
                }
            };
        });
    }
}

/*@Injectable()
export class ChatService {
    constructor(private http: Http) {
    }

    get(url: string): Observable<any> {
        let options = new RequestOptions();
        options.headers = new Headers();
        options.headers.append('AUTH-TOKEN', 'SomeToken123');
        options.responseType = ResponseContentType.Blob;

        return new Observable((observer: Subscriber<any>) => {
            let objectUrl: string = null;

            this.http
                .get(url, options)
                .subscribe(m => {
                    objectUrl = URL.createObjectURL(m.blob());
                    observer.next(objectUrl);
                });

            return () => {
                if (objectUrl) {
                    URL.revokeObjectURL(objectUrl);
                    objectUrl = null;
                }
            };
        });
    }
}*/